<template>
  <div class="row">
    <div class="col-12 page-title">{{ title }}</div>
    <div class="col-md-6">
      <div class="title">{{ $t("title.promotion") }}</div>
      <page-table
        :items="promotions"
        :columns="columns"
        :idProperty="idProperty"
        :nameProperty="nameProperty"
        :getTrigger="getTrigger"
        @getItems="getItems"
        @addItem="addItem"
        @editItem="editItem"
        @removeItem="removeItem"
        @currentIndexChanged="currentIndexChanged"
        :hasPage="false"
        :viewName="promotionTypeName"
        tableName="group"
      >
        <th
          v-if="isRepeat"
          class="text-center"
          slot="th-after"
          style="width: 100px"
        >
          {{ $t("columnName.isrepeat") }}
        </th>
        <td
          v-if="isRepeat"
          class="text-center"
          slot="td-after"
          slot-scope="props"
        >
          <span v-if="props.item.IsRepeat">V</span>
        </td>
      </page-table>
      <div v-if="hasDet">
        <div class="title">{{ $t("title.promotiondets") }}</div>
        <page-table
          :items="promotionDets"
          :columns="detColumns"
          :idProperty="detIdProperty"
          :nameProperty="detNameProperty"
          :getTrigger="getDetTrigger"
          @getItems="getDetItems"
          @addItem="addDetItem"
          @editItem="editDetItem"
          @removeItem="removeDetItem"
          @currentIndexChanged="currentDetIndexChanged"
          :hasPage="false"
          :viewName="promotionTypeName"
          tableName="groupDet"
        ></page-table>
      </div>
    </div>
    <div class="col-md-6">
      <div class="title">{{ $t("title.selectedfood") }}</div>
      <div class="card card-table">
        <div class="card-header">
          <button
            v-if="promotionType == 5"
            type="button"
            class="btn btn-outline-success btn-icon"
            @click="onUpdatePrice"
          >
            <i class="fas fa-pen"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-icon"
            @click="onSelectFoodClick"
          >
            <i class="fa fa-plus"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-danger btn-icon"
            @click="onRemove"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
          <import-part
            @onImport="onImport"
            :title="`${$t('modal.importpromotion')}`"
          />
          <export-part @onExport="onExport" />
        </div>
        <div class="card-body">
          <div class="form-group row" v-if="promotionType == 5">
            <label for="selectSalPrice" class="col-sm-2 col-form-label"
              >{{ $t("modal.unitprice") }}:</label
            >
            <div class="col-sm-10">
              <input
                type="number"
                class="form-control"
                id="selectSalPrice"
                v-model="selectSalPrice"
              />
            </div>
          </div>
          <table-part
            :columns="foodColumns"
            :idProperty="idFoodProperty"
            :items="selecteds"
            @click="selectFoodClick"
            :viewName="promotionTypeName"
            tableName="selecteds"
          >
            <th style="width: 120px" slot="th-before">
              <div class="custom-control custom-checkbox mr-sm-2">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="allRemove"
                  v-model="allRemove"
                />
                <label class="custom-control-label" for="allRemove">{{
                  $t("columnName.remove")
                }}</label>
              </div>
            </th>
            <td slot="td-before" slot-scope="props">
              <input
                type="checkbox"
                class="checkbox"
                :value="props.item.FoodId"
                v-model="removeIds"
              />
            </td>
          </table-part>
        </div>
      </div>
    </div>

    <item-modal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveItem"
      :title="modalTitle"
      modalId="promotion"
    >
      <div slot="item">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="promotionName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="promotionName"
                v-model="promotion.PromotionName"
                required
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="dayModule">{{ $t("modal.daymodule") }}</label>
              <select
                class="form-control"
                id="dayModule"
                v-model="promotion.DayModuleId"
              >
                <option
                  v-for="dayModule in dayModules"
                  :key="dayModule.ModuleId"
                  :value="dayModule.ModuleId"
                >
                  {{ dayModule.ModuleName }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="!isRetail" class="col-6">
            <div class="form-group">
              <label for="eatType">{{ $t("modal.eatsearchtype") }}</label>
              <select
                class="form-control"
                id="eatType"
                v-model="promotion.EatType"
              >
                <option
                  v-for="eatSearchType in eatSearchTypes"
                  :key="eatSearchType.value"
                  :value="eatSearchType.value"
                >
                  {{ eatSearchType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12" v-if="this.promotionType != 5">
            <div class="form-group">
              <label for="saleFoodId">{{ $t("modal.customizefood") }}</label>
              <div class="input-group mb-3">
                <input
                  id="saleFoodId"
                  type="text"
                  class="form-control"
                  :value="promotion.SaleFoodName"
                  @dblclick="foodModalTrigger = !foodModalTrigger"
                  :placeholder="`${$t('placeholder.dbselect')}`"
                  readonly
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-danger"
                    type="button"
                    @click="setSaleFood"
                  >
                    {{ $t("button.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="isRepeat">
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isRepeat"
                  v-model="promotion.IsRepeat"
                />
                <label class="custom-control-label" for="isRepeat">{{
                  $t("modal.isrepeat")
                }}</label>
              </div>
            </div>
          </div>
          <div class="col-6" v-if="promotionType == 2">
            <div class="form-group">
              <label for="total">{{ $t("modal.total") }}</label>
              <input
                type="number"
                class="form-control"
                id="total"
                v-model="promotion.Total"
                required
              />
            </div>
          </div>
          <div
            class="col-12 mt-3"
            v-if="promotionType == 8 || promotionType == 10"
          >
            <div class="form-group row">
              <label for="fullNumber2" class="col-2 col-form-label">{{
                label1
              }}</label>
              <div class="col-4">
                <input
                  type="number"
                  class="form-control"
                  id="fullNumber2"
                  v-model="promotion.FullNumber"
                  required
                />
              </div>
              <label for="total2" class="col-1 col-form-label">{{
                label2
              }}</label>
              <div class="col-4">
                <input
                  type="number"
                  class="form-control"
                  id="total2"
                  v-model="promotion.Total"
                  required
                />
              </div>
              <label for="total" class="col-1 col-form-label">{{
                label3
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </item-modal>
    <promotion-det-view
      :promotionId="currentPromotionId"
      :id="editPromotionDetId"
      :trigger="openDetTrigger"
      :modalTitle="detsModalTitle"
      :promotionType="promotionType"
      @onSaved="getDetTrigger = !getDetTrigger"
    ></promotion-det-view>
    <food-selects
      :trigger="foodModalTrigger"
      :isMultiple="false"
      @onSaved="setSaleFood"
    ></food-selects>
    <food-selects
      modalId="foodChooseModal"
      :trigger="foodChooseModalTrigger"
      :isMultiple="true"
      :noPromotion="true"
      :promotionModuleId="moduleId"
      @onSaved="addFoods"
    ></food-selects>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import tablePart from "@/components/parts/tablePart";
import itemModal from "@/components/ItemModal";
import promotionDetView from "@/components/promotions/PromotionDets";
import foodSelects from "@/components/selects/FoodSelects";
import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

export default {
  data() {
    return {
      title: "",
      moduleId: "",
      promotionType: -1,
      isSame: false,
      promotions: [],
      promotion: {},
      dayModules: [],
      eatSearchTypes: this.$enum.eatSearchType.getAll(),
      columns: [
        { name: "PromotionName" },
        { name: "DayModuleName" },
        { name: "EatTypeText" },
      ],
      idProperty: "PrmotionId",
      nameProperty: "PromotionName",
      getTrigger: false,
      isShowModal: false,
      modalTitle: "",
      detsModalTitle: "",
      label1: "",
      label2: "",
      label3: "",

      currentPromotionId: "",
      promotionDets: [],
      currentPromotionDetId: "",
      editPromotionDetId: "",
      detColumns: [{ name: "PromotionDetName" }],
      detIdProperty: "PrmotionDetId",
      detNameProperty: "PromotionDetName",
      getDetTrigger: false,
      openDetTrigger: false,

      selecteds: [],
      addIds: [],
      removeIds: [],
      foodColumns: [{ name: "FoodNumber" }, { name: "FoodName" }],
      idFoodProperty: "FoodId",
      nameFoodProperty: "FoodName",
      allRemove: false,
      foodModalTrigger: false,

      hasDet: true,
      notSelectSalPrice: 0,
      selectSalPrice: 0,
      foodChooseModalTrigger: false,
      industryType: 0,
    };
  },
  components: {
    pageTable,
    tablePart,
    itemModal,
    promotionDetView,
    foodSelects,
    importPart,
    exportPart,
  },
  computed: {
    isRetail() {
      return this.industryType == 2;
    },
    isRepeat() {
      return this.promotionType == 6 || this.promotionType == 9;
    },
    promotionTypeName() {
      return this.$enum.promotionType.getByValue(this.promotionType).name;
    },
  },
  watch: {
    currentPromotionId() {
      this.getDetTrigger = !this.getDetTrigger;
      if (this.promotionType != 2) this.updateFoods();
    },
    currentPromotionDetId() {
      if (this.promotionType == 2) this.updateFoodsByDet();
    },
    currentFoodKindId() {
      this.updateFoods();
    },
    allRemove(value) {
      this.removeIds = [];
      if (value) {
        this.selecteds.forEach((item) => this.removeIds.push(item.FoodId));
      }
    },
    isShowModal(value) {
      if (value) {
        const vm = this;
        this.$api.modules
          .getDayModules()
          .then((data) => (vm.dayModules = data));
      }
    },
  },
  methods: {
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      if (this.promotionType == 2) {
        this.$api.promotionDets
          .postExcel(this.currentPromotionDetId, formData)
          .then(() => {
            vm.getTrigger = !vm.getTrigger;
          });
      } else {
        this.$api.promotions
          .postExcel(this.currentPromotionId, formData)
          .then(() => {
            vm.getTrigger = !vm.getTrigger;
          });
      }
    },
    onExport() {
      if (this.promotionType == 2) {
        this.$api.promotionDets.getExcel(this.currentPromotionDetId);
      } else {
        this.$api.promotions.getExcel(this.currentPromotionId);
      }
    },
    noSelectFoodClick(foodId) {
      const index = this.addIds.indexOf(foodId);
      if (index >= 0) {
        this.addIds.splice(index, 1);
      } else {
        this.addIds.push(foodId);
      }
    },
    selectFoodClick(item) {
      const index = this.removeIds.indexOf(item.FoodId);
      if (index >= 0) {
        this.removeIds.splice(index, 1);
      } else {
        this.removeIds.push(item.FoodId);
      }
    },
    currentIndexChanged(index) {
      const promotion = this.promotions[index];
      if (promotion) this.currentPromotionId = promotion.PromotionId;
      else this.currentPromotionId = "";
    },
    getItems() {
      this.$api.promotions
        .gets(this.moduleId, this.promotionType, this.isSame)
        .then((data) => {
          this.promotions = data;
        });
    },
    addItem() {
      this.promotion = {
        ModuleId: this.moduleId,
        PromotionType: this.promotionType,
        SaleFoodName: "",
        IsSame: this.isSame,
      };
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addpromotion");
    },
    editItem(item) {
      const vm = this;
      this.$api.promotions.get(item.PromotionId).then((data) => {
        vm.promotion = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.editpromotion");
      });
    },
    removeItem(item) {
      const vm = this;
      this.$api.promotions.remove(item.PromotionId).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    saveItem() {
      const vm = this;
      this.$api.promotions.save(vm.promotion).then(() => {
        vm.isShowModal = !vm.isShowModal;
        vm.getTrigger = !vm.getTrigger;
      });
    },
    currentDetIndexChanged(index) {
      const promotionDet = this.promotionDets[index];
      if (promotionDet)
        this.currentPromotionDetId = promotionDet.PromotionDetId;
      else this.currentPromotionDetId = "";
    },
    getDetItems() {
      if (!this.currentPromotionId || this.currentPromotionId.length == 0)
        return;
      const vm = this;
      this.$api.promotionDets.gets(vm.currentPromotionId).then((data) => {
        vm.promotionDets = data;
      });
    },
    addDetItem() {
      if (!this.currentPromotionId || this.currentPromotionId.length == 0) {
        this.$func.message.alert("請選擇一筆群組 !");
        return;
      }
      this.editPromotionDetId = "";
      this.openDetTrigger = !this.openDetTrigger;
      this.detsModalTitle = this.$t("modal.addpromotiondets");
    },
    editDetItem() {
      this.editPromotionDetId = this.currentPromotionDetId;
      this.openDetTrigger = !this.openDetTrigger;
      this.detsModalTitle = this.$t("modal.editpromotiondets");
    },
    removeDetItem(item) {
      const vm = this;
      this.$api.promotionDets.remove(item.PromotionDetId).then(() => {
        vm.getDetTrigger = !vm.getDetTrigger;
      });
    },
    updateFoods() {
      if (!this.currentPromotionId || this.currentPromotionId.length == 0)
        return;
      const vm = this;
      this.$api.foodPromotions.getFoods(vm.currentPromotionId).then((data) => {
        vm.removeIds = [];
        vm.selecteds = data;
        vm.allRemove = false;
      });
    },
    updateFoodsByDet() {
      if (!this.currentPromotionDetId || this.currentPromotionDetId.length == 0)
        return;
      const vm = this;
      this.$api.foodPromotions
        .getFoodsByDet(vm.currentPromotionDetId)
        .then((data) => {
          vm.removeIds = [];
          vm.selecteds = data;
          vm.allRemove = false;
        });
    },
    onAdd() {
      if (this.addIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      if (
        this.promotionType == 2 &&
        (!this.currentPromotionDetId || this.currentPromotionDetId.length == 0)
      ) {
        this.$func.message.alert("請選擇小群組 !");
        return;
      }
      var items = {
        PromotionId: this.currentPromotionId,
        FoodIds: this.addIds,
        SalPrice: this.notSelectSalPrice,
      };
      if (this.promotionType == 2)
        items.PromotionDetId = this.currentPromotionDetId;
      this.$api.foodPromotions.postFoods(items).then(() => {
        if (this.promotionType == 2) this.updateFoodsByDet();
        else this.updateFoods();
        this.notSelectSalPrice = 0;
        this.addIds = [];
      });
    },
    onRemove() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      var items = {
        PromotionId: this.currentPromotionId,
        FoodIds: this.removeIds,
      };
      if (this.promotionType == 2)
        items.PromotionDetId = this.currentPromotionDetId;
      this.$api.foodPromotions.removeFoods(items).then(() => {
        if (this.promotionType == 2) this.updateFoodsByDet();
        else this.updateFoods();
      });
    },
    setSaleFood(item) {
      if (item) {
        this.promotion.SaleFoodId = item.FoodId;
        this.promotion.SaleFoodName = item.FoodName;
      } else {
        this.promotion.SaleFoodId = undefined;
        this.promotion.SaleFoodName = undefined;
      }
    },
    onUpdatePrice() {
      if (this.removeIds.length == 0) {
        this.$func.message.alert("請選擇商品 !");
        return;
      }
      const vm = this;
      this.$api.foodPromotions
        .postFoods({
          PromotionId: vm.currentPromotionId,
          FoodIds: vm.removeIds,
          SalPrice: vm.selectSalPrice,
        })
        .then(() => {
          vm.updateFoods();
          vm.selectSalPrice = 0;
        });
    },
    onSelectFoodClick() {
      if (this.promotionType == 2) {
        if (
          !this.currentPromotionDetId ||
          this.currentPromotionDetId.length == 0
        ) {
          this.$func.message.alert("請選擇一筆小群組 !");
          return;
        }
      } else {
        if (!this.currentPromotionId || this.currentPromotionId.length == 0) {
          this.$func.message.alert("請選擇一筆群組 !");
          return;
        }
      }

      this.foodChooseModalTrigger = !this.foodChooseModalTrigger;
    },
    addFoods(items) {
      if (!this.currentPromotionId || this.currentPromotionId.length == 0) {
        this.$func.message.alert("請選擇一筆群組 !");
        return;
      }
      items.forEach((item) => {
        this.addIds.push(item.FoodId);
      });
      this.onAdd();
    },
    setDetsColumns() {
      switch (this.promotionType) {
        case 0:
          this.detColumns.push({ name: "FullNumber", headerText: "滿" });
          this.detColumns.push({ name: "Total", headerText: "小計" });
          this.columns.push({ name: "SaleFoodName" });
          break;
        case 1:
          this.detColumns.push({ name: "FullNumber", headerText: "買" });
          this.detColumns.push({
            name: "Total",
            headerText: "送",
            filter: "empty",
          });
          this.columns.push({ name: "SaleFoodName" });
          break;
        case 2:
          this.columns.push({ name: "Total" });
          this.columns.push({ name: "SaleFoodName" });
          break;
        case 3:
        case 7:
          this.detColumns.push({ name: "FullNumber", headerText: "滿" });
          this.detColumns.push({
            name: "Total",
            headerText: "%",
            filter: "empty",
          });
          this.columns.push({ name: "SaleFoodName" });
          break;
        case 4:
          this.detColumns.push({ name: "FullNumber", headerText: "滿" });
          this.detColumns.push({ name: "Total", headerText: "單價" });
          this.columns.push({ name: "SaleFoodName" });
          break;
        case 5:
          this.hasDet = false;
          this.foodColumns.push({ name: "SalPrice" });
        case 6:
        case 9:
          this.detColumns.push({ name: "FullNumber", headerText: "滿" });
          this.detColumns.push({ name: "Total", headerText: "折價" });
          this.columns.push({ name: "SaleFoodName" });
          break;
        case 8:
          this.hasDet = false;
          this.columns.push({ name: "SaleFoodName" });
          this.columns.push({ name: "FullNumber", headerText: "第N件" });
          this.columns.push({
            name: "Total",
            headerText: "%",
            filter: "empty",
          });
          this.label1 = this.$t("modal.from");
          this.label2 = this.$t("modal.amount2");
          this.label3 = "%";
          break;
        case 10:
          this.hasDet = false;
          this.columns.push({ name: "SaleFoodName" });
          this.columns.push({ name: "FullNumber", headerText: "第N件" });
          this.columns.push({
            name: "Total",
            headerText: "%",
            filter: "empty",
          });
          this.label1 = this.$t("modal.greaterthan");
          this.label2 = this.$t("modal.amount2");
          this.label3 = "%";
          break;
      }
    },
  },
  created() {
    this.industryType = parseInt(window.localStorage["industryType"]);
    if (this.industryType == 2) {
      let index = this.columns.findIndex((col) => {
        return col.name == "EatTypeText";
      });
      this.columns.splice(index, 1);
    }
    this.moduleId = this.$route.params["id"];
    this.promotionType = Number(this.$route.params["type"]);
    this.isSame = this.$route.params["isSame"].toString() == "true";
    if (this.promotionType < 0) this.$router.go(-1);
    this.title = this.$t(
      `option.${this.$enum.promotionType.getByValue(this.promotionType).name}`
    );
    if (this.isSame) {
      this.title = this.title + "(同商品)";
    }
    this.setDetsColumns();
  },
};
</script>
