<template>
  <div class="row">
    <div class="col-md-6">
      <h5>{{ $t("title.directorykind") }}</h5>
      <page-table
        :parentPage="pagination"
        :items="directoryKinds"
        :columns="columns"
        :idProperty="idProperty"
        :nameProperty="nameProperty"
        @getItems="getDirectoryKinds"
        @addItem="addDirectoryKind"
        @editItem="editDirectoryKind"
        @removeItem="removeDirectoryKind"
        @trdbClicked="editDirectoryKind"
        @currentIndexChanged="currentIndexChanged"
        :getTrigger="getTrigger"
        :hasAdd="authority.IsAdd"
        :hasEdit="authority.IsUpdate"
        :hasRemove="authority.IsRemove"
        :isSortable="true"
      >
      </page-table>
    </div>
    <div class="col-md-6">
      <h5>{{ $t("title.directory") }}</h5>
      <directory :directoryKindId="currentKindId" />
    </div>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveDirectoryKind"
      :title="modalTitle"
      modalId="directorKindModal"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="directoryType">{{ $t("modal.kind") }}</label>
              <select
                class="form-control"
                id="directoryType"
                v-model="directoryKind.DirectoryType"
                required
              >
                <option
                  v-for="directoryType in directoryTypes"
                  :key="directoryType.value"
                  :value="directoryType.value"
                >
                  {{ directoryType.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="directoryKindName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="directoryKindName"
                v-model="directoryKind.DirectoryKindName"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import directory from "@/components/datas/Directorys";

export default {
  data() {
    return {
      directoryTypes: [],
      directoryKinds: [],
      directoryKind: {},
      currentKindId: "",
      pagination: {},
      isShowModal: false,
      isShowFood: false,
      modalTitle: "",
      columns: [{ name: "DirectoryKindName" }, { name: "DirectoryTypeText" }],
      idProperty: "DirectoryKindId",
      nameProperty: "DirectoryKindName",
      getTrigger: false,
      authority: {},
    };
  },
  components: {
    pageTable,
    itemModal,
    directory,
  },
  computed: {
    hasUberEats() {
      return window.localStorage["hasUberEats"] == "true";
    },
    industryType() {
      return parseInt(window.localStorage["industryType"]);
    },
    isCustomer() {
      return window.localStorage["isCustomer"] == "true";
    },
    hasOnlineOrder() {
      return window.localStorage["hasOnlineOrder"] == "true";
    },
    isERP() {
      return window.localStorage["isERP"] == "true";
    },
    currentLang() {
      return this.$store.state.currentLang;
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
  },
  methods: {
    setOptions() {
      if (this.industryType == 2) {
        this.directoryTypes = this.$enum.directoryType.getWithIndustryRetail();
      } else {
        this.directoryTypes = this.$enum.directoryType.getAll();
      }
    },
    currentIndexChanged(index) {
      const kind = this.directoryKinds[index];
      if (kind) {
        this.currentKindId = kind.DirectoryKindId;
      }
    },
    getDirectoryKinds(dTO) {
      const vm = this;
      this.$api.directoryKinds.getPages(dTO).then((data) => {
        vm.directoryKinds = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addDirectoryKind() {
      this.directoryKind = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.adddirectorykind");
    },
    editDirectoryKind(directoryKind) {
      if (!this.authority.IsUpdate) {
        return;
      }
      const vm = this;
      this.$api.directoryKinds
        .get(directoryKind.DirectoryKindId)
        .then((data) => {
          vm.directoryKind = data;
          vm.isShowModal = !vm.isShowModal;
          vm.modalTitle = this.$t("modal.editdirectorykind");
        });
    },
    removeDirectoryKind(directoryKind) {
      const vm = this;
      this.$api.directoryKinds
        .remove(directoryKind.DirectoryKindId)
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        });
    },
    saveDirectoryKind() {
      const vm = this;
      this.$api.directoryKinds
        .save(vm.directoryKind)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
  },
  mounted() {
    this.setOptions();
    if (!this.isCustomer) {
      let type = this.directoryTypes.findIndex((item) => {
        return item.name == "VoidDeposit";
      });
      this.directoryTypes.splice(type, 1);
    }
    if (!this.hasOnlineOrder && !!this.hasUberEats) {
      let type = this.directoryTypes.findIndex((item) => {
        return item.name == "RejectOnlineOrder";
      });
      this.directoryTypes.splice(type, 1);
    }
    if (!this.isERP) {
      let type = this.directoryTypes.findIndex((item) => {
        return item.name == "StockAdjust";
      });
      this.directoryTypes.splice(type, 1);
    }
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
