import * as directoryType from "./directoryType";
import * as moduleType from "./moduleType";
import * as payType from "./payType";
import * as promotionType from "./promotionType";
import * as roleType from "./roleType";
import * as taxType from "./taxType";
import * as tradeType from "./tradeType";
import * as customerSaleType from "./customerSaleType";
import * as customerLevelType from "./customerLevelType";
import * as salPriceType from "./salPriceType";
import * as salPricePrintType from "./salPricePrintType";
import * as degreeType from "./degreeType";
import * as eatType from "./eatType";
import * as takeOutType from "./takeOutType";
import * as eatSearchType from "./eatSearchType";
import * as timeType from "./timeType";
import * as priceLabelType from "./priceLabelType";
import * as tradeStatusType from "./tradeStatusType";
import * as purchaseOrderStatusType from "./purchaseOrderStatusType";
import * as rankType from "./rankType";
import * as overPayType from "./overPayType";
import * as invoiceTargetType from "./invoiceTargetType";
import * as vehicleType from "./vehicleType";
import * as inventoryMachineType from "./inventoryMachineType";
import * as productType from "./productType";
import * as foodStockType from "./foodStockType";
import * as costType from "./costType";
import * as employeeType from "./employeeType";

import * as erpType from "./erpType";
import * as waitingType from "./waitingType";
import * as waitingIntervalType from "./waitingIntervalType";
import * as reserveDaysType from "./reserveDaysType";

import * as foodSearchType from "./searchs/foodSearchType";
import * as customerSearchType from "./searchs/customerSearchType";
import * as supplierSearchType from "./searchs/supplierSearchType";

import * as stockAdjustType from "./stockAdjustType";

import * as roundType from "./roundType";
import * as detailRoundType from "./detailRoundType";

import * as ocardPromotionType from "./ocardPromotionType";

import * as utaiProductType from "./utaiProductType";
import * as utaiStatusType from "./utaiStatusType";
import * as utaiLocationType from "./utaiLocationType";
import * as utaiApplyType from "./utaiApplyType";
import * as utaiTermLeaseType from "./utaiTermLeaseType";

import * as businessClassType from "./businessClassType";

import * as salerType from "./salerType";
import * as sourceType from "./sourceType";
import * as businessType from "./businessType";
import * as eInvType from "./eInvType";
import * as ushowStatusType from "./ushowStatusType";

import * as reportSourceType from "./reportSourceType";

import * as lineOrderImageColorType from "./lineOrderImageColorType";
import * as foodStatusType from "./foodStatusType";

import * as visionType from "./visionType";

import * as dateSourceType from "./dateSourceType";

import * as changePriceType from "./changePriceType";

import * as qtyType from "./qtyType";

import * as stockAvailableType from "./stockAvailableType";

import * as elecInvoiceTitleType from "./elecInvoiceTitleType";

import * as apiType from "./apiType";
import * as thirdPartyType from "./thirdPartyType";
import * as systemLogType from "./systemLogType";
import * as notificationType from "./notificationType";
import * as webhookType from "./webhookType";

import * as appType from "./appType";

import * as tableOrderType from "./tableOrderType";

import * as customerIdFillType from "./customerIdFillType";

import * as industryType from "./industryType";

import * as environmentType from "./environmentType";

import * as downloadSyncType from "./downloadSyncType";
import * as ERPCustomerIntegrateType from "./ERPCustomerIntegrateType";

export default {
  directoryType,
  moduleType,
  payType,
  promotionType,
  roleType,
  taxType,
  tradeType,
  customerSaleType,
  customerLevelType,
  salPriceType,
  salPricePrintType,
  degreeType,
  eatType,
  takeOutType,
  eatSearchType,
  timeType,
  foodSearchType,
  supplierSearchType,
  priceLabelType,
  tradeStatusType,
  purchaseOrderStatusType,
  rankType,
  overPayType,
  invoiceTargetType,
  vehicleType,
  inventoryMachineType,
  productType,
  customerSearchType,
  erpType,
  waitingType,
  waitingIntervalType,
  reserveDaysType,
  foodStockType,
  costType,
  stockAdjustType,
  roundType,
  detailRoundType,
  ocardPromotionType,
  utaiProductType,
  utaiStatusType,
  utaiLocationType,
  utaiApplyType,
  utaiTermLeaseType,
  businessClassType,
  salerType,
  sourceType,
  businessType,
  eInvType,
  ushowStatusType,
  reportSourceType,
  lineOrderImageColorType,
  foodStatusType,
  visionType,
  dateSourceType,
  changePriceType,
  qtyType,
  stockAvailableType,
  employeeType,
  elecInvoiceTitleType,
  apiType,
  thirdPartyType,
  systemLogType,
  notificationType,
  webhookType,
  appType,
  tableOrderType,
  customerIdFillType,
  industryType,
  environmentType,
  downloadSyncType,
  ERPCustomerIntegrateType,
};
