<template>
  <div class="table-responsive custom-table">
    <table
      class="table table-hover"
      :style="
        isBusiness
          ? { width: getTotalWidth(), cursor: cursor }
          : { width: getTotalWidth() }
      "
    >
      <thead style="user-select: none" :id="id">
        <tr @click.right.prevent="showColumnProperty">
          <slot name="th-before" />
          <th
            v-for="column in showColumns"
            :key="column.ColumnName"
            :style="{ width: column.Width + 'px', position: 'relative' }"
            :class="column.class"
            @click="thClick(column)"
          >
            {{ column.text }}
            <span
              v-if="sortName == column.ColumnName"
              :class="{ isReverse: isDesc == 1 }"
            >
              <i class="fas fa-angle-down"></i>
            </span>
            <span v-if="column.hasDropDown">{{
              "(" + currentDetsMemo.text + ")"
            }}</span>
            <a
              v-if="column.hasDropDown"
              class="dropdown-toggle"
              style="
                position: absolute;
                top: 8px;
                left: 0px;
                padding: 5px 10px 5px 100px;
                color: black;
              "
              href="#"
              id="customizeMemo"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></a>
            <div class="dropdown-menu" aria-labelledby="customizeMemo">
              <div
                class="dropdown-item"
                @click="setDetsMemo({ value: 0, text: '自訂' })"
              >
                自訂
              </div>
              <div
                class="dropdown-item"
                @click="setDetsMemo({ value: 1, text: '條碼1' })"
              >
                條碼1
              </div>
              <div
                class="dropdown-item"
                @click="setDetsMemo({ value: 2, text: '條碼2' })"
              >
                條碼2
              </div>
              <div
                class="dropdown-item"
                @click="setDetsMemo({ value: 3, text: '條碼3' })"
              >
                條碼3
              </div>
              <div
                class="dropdown-item"
                @click="setDetsMemo({ value: 4, text: '商品備註' })"
              >
                商品備註
              </div>
            </div>
            <div
              v-if="isBusiness"
              style="
                top: 0;
                right: -10px;
                bottom: 0;
                width: 20px;
                position: absolute;
                cursor: col-resize;
              "
              @mousedown="mousedown($event, column)"
              @click.stop="1 == 1"
            ></div>
          </th>
          <slot name="th-after" />
        </tr>
      </thead>
      <slot name="tbody">
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item[idProperty]"
            @click="click(item, index)"
            :class="{ 'tr-selected': currentIndex == index }"
            @dblclick="dbClicked(item)"
          >
            <slot name="td-before" :item="item" :index="index" />
            <tdPart
              v-for="column in showColumns"
              :key="column.ColumnName"
              :column="column"
              :item="item"
            />
            <slot name="td-after" :item="item" />
          </tr>
          <tr v-if="hasTotal && items.length > 0" class="tr-total">
            <td
              v-for="(itemTotal, index) in itemTotals"
              :key="index"
              :class="itemTotal.class"
            >
              {{ itemTotal.value }}
            </td>
          </tr>
        </tbody>
      </slot>
    </table>
    <column-properties
      :columns="validColumns"
      :viewName="validViewName"
      :tableName="validTableName"
      :modalId="modalId"
      :trigger="trigger"
      @saved="save"
    ></column-properties>
  </div>
</template>

<script>
import tdPart from "./tdPart";
import columnProperties from "@/components/parts/columnProperties";
import currencyFilter from "@/filters/currency";
import discountFilter from "@/filters/discount";

export default {
  data() {
    return {
      currentIndex: -1,
      currentTh: undefined,
      startOffset: undefined,
      cursor: "pointer",
      trigger: false,
      validColumns: [],
      id: this.$func.uuid.get(),
      sortName: "",
      isDesc: 0,
      itemTotals: [],
      validTableName: this.getValidTableName(),
      currentDetsMemo: { value: 0, text: "自訂" },
    };
  },
  props: [
    "parentIndex",
    "columns",
    "items",
    "idProperty",
    "changeIndex",
    "viewName",
    "tableName",
    "noHide",
    "noInitialTableName",
    "initialWidth",
    "isSortable",
    "isMultiple",
    "modalId",
    "hasTotal",
  ],
  components: {
    tdPart,
    columnProperties,
  },
  computed: {
    currentLang() {
      return this.$store.state.currentLang;
    },
    showColumns() {
      return this.validColumns.filter((col) => {
        return col.IsVisible;
      });
    },
    validViewName() {
      if (this.viewName) return this.viewName;
      else return this.$route.name;
    },
    // validTableName() {
    //   if (this.tableName) return this.tableName;
    //   else if (this.noInitialTableName) return undefined;
    //   else return "data";
    // },
    isBusiness() {
      return window.localStorage["roleType"] == 0;
    },
  },
  watch: {
    tableName() {
      this.validTableName = this.getValidTableName();
      this.updateValidColumns();
    },
    showColumns() {
      this.computeTotals();
    },
    currentLang() {
      this.updateValidColumns();
    },
    currentIndex() {
      this.currentIndexChanged();
    },
    parentIndex(value) {
      this.currentIndex = value;
    },
    items() {
      if (this.currentIndex < 0 || this.currentIndex > this.items.length) {
        if (this.items.length > 0) {
          this.currentIndex = 0;
        } else this.currentIndex = -1;
      }
      this.computeTotals();
    },
    changeIndex(value) {
      this.currentIndex = value;
    },
  },
  methods: {
    setDetsMemo(item) {
      const memoToggle = document.getElementById("customizeMemo");
      this.currentDetsMemo = item;
      if (item.value == 0) {
        memoToggle.style.padding = "5px 10px 5px 100px";
      } else if (item.value == 4) {
        memoToggle.style.padding = "5px 10px 5px 132px";
      } else {
        memoToggle.style.padding = "5px 10px 5px 110px";
      }
      this.$emit("setDetsMemo", item);
    },
    getValidTableName() {
      if (this.tableName) return this.tableName;
      else if (this.noInitialTableName) return undefined;
      else return "data";
    },
    calculateTotal(dividendName, divisorName, column) {
      let dividend = 0;
      let divisor = 0;
      this.items.forEach((item) => {
        if (item[dividendName]) {
          dividend = dividend + Number(item[dividendName]);
        }
        if (item[divisorName]) {
          divisor = divisor + Number(item[divisorName]);
        } else {
          divisor = 1;
        }
      });
      this.totalFilter(column, dividend / divisor);
    },
    totalFilter(column, val) {
      val = Math.round(val * 10000) / 10000;
      let obj = {};
      if (column.filter == "currency") {
        obj = {
          value: currencyFilter(val),
          class: column.class,
        };
      } else if (column.filter == "discount") {
        obj = {
          value: discountFilter(val * 100),
          class: column.class,
        };
      } else {
        obj = {
          value: val,
          class: column.class,
        };
      }
      this.itemTotals.push(obj);
    },
    computeTotals() {
      this.itemTotals = [];
      if (this.hasTotal && this.items.length > 0) {
        let arr = this.showColumns;
        arr.forEach((column) => {
          if (
            column.ColumnName == "Total" ||
            column.ColumnName == "Cash" ||
            column.ColumnName == "CreditCardPrice" ||
            column.ColumnName == "DiscountPrice" ||
            column.ColumnName == "FreePrice" ||
            column.ColumnName == "PayType1" ||
            column.ColumnName == "PayType2" ||
            column.ColumnName == "LinePay" ||
            column.ColumnName == "Scan2Pay" ||
            column.ColumnName == "TotalCost" ||
            column.ColumnName == "GrossProfit" ||
            column.ColumnName == "People" ||
            column.ColumnName == "Sheets" ||
            column.ColumnName == "FoodCounts"
          ) {
            this.calculateTotal(column.ColumnName, undefined, column);
          } else if (column.ColumnName == "GrossMargin") {
            column.filter = "discount";
            this.calculateTotal("GrossProfit", "Total", column);
          } else if (column.ColumnName == "FoodAverageSalPrice") {
            this.calculateTotal("Total", "FoodCounts", column);
          } else if (column.ColumnName == "TotalPerReceipt") {
            this.calculateTotal("Total", "Sheets", column);
          } else if (column.ColumnName == "FoodCountsPerReceipt") {
            this.calculateTotal("FoodCounts", "Sheets", column);
          } else if (column.ColumnName == "PeoplePerReceipt") {
            this.calculateTotal("People", "Sheets", column);
          } else if (column.ColumnName == "TotalPerPerson") {
            this.calculateTotal("Total", "People", column);
          } else if (column.ColumnName == "FoodCountsPerPerson") {
            this.calculateTotal("FoodCounts", "People", column);
          } else {
            this.itemTotals.push({ value: "", class: undefined });
          }
        });
      }
    },
    thClick(column) {
      let columnName = column.ColumnName;
      if (this.isSortable) {
        if (this.sortName != columnName) {
          this.sortName = columnName;
          this.isDesc = 0;
        } else {
          this.sortName = columnName;
          this.isDesc = this.isDesc == 0 ? 1 : 0;
        }
        this.$emit("getSort", { sortName: this.sortName, isDesc: this.isDesc });
      }
    },
    getTotalWidth() {
      let width = this.initialWidth ? parseInt(this.initialWidth) : 0;
      this.showColumns.forEach((column) => {
        width = width + column.Width;
      });
      if (this.$slots["th-before"]) {
        this.$slots["th-before"].forEach((th) => {
          if (th.data) {
            width =
              width + parseInt(th.data.staticStyle["width"].replace("px", ""));
          }
        });
      }
      if (this.$slots["th-after"]) {
        this.$slots["th-after"].forEach((th) => {
          if (th.data) {
            width =
              width + parseInt(th.data.staticStyle["width"].replace("px", ""));
          }
        });
      }
      return width + "px";
    },
    updateValidColumns() {
      let allColumns = this.columns.map((column, index) => {
        let map = {
          ColumnName: column.name,
          Width: column.width,
          class: column.class,
          filter: column.filter,
          IsVisible: true,
          Position: index,
        };
        if (column.hasDropDown) map.hasDropDown = column.hasDropDown;
        if (column.headerText) map.headerText = column.headerText;
        if (column.defaultVisible) map.defaultVisible = column.defaultVisible;
        return map;
      });

      if (this.isBusiness) {
        this.$api.columnProperties
          .get(this.validViewName, this.validTableName)
          .then((data) => {
            let columns = [];
            if (data.length <= 0) {
              this.$api.columnProperties.save(
                this.validViewName,
                this.validTableName,
                allColumns
              );
              columns = allColumns;
            } else {
              if (this.noHide) {
                allColumns.forEach((column) => {
                  data.forEach((d) => {
                    if (column.ColumnName == d.ColumnName) {
                      column.ColumnName = d.ColumnName;
                      column.Width = d.Width;
                      column.class = d.class;
                      column.filter = d.filter;
                    }
                  });
                });
              }
              let isModify = false;
              let removeColumnIndexs = [];
              data.forEach((column, index) => {
                const ind = allColumns.findIndex(
                  (d) => d.ColumnName == column.ColumnName
                );
                if (ind < 0) {
                  isModify = true;
                  removeColumnIndexs.push(index);
                } else {
                  if (allColumns[ind].headerText)
                    column.headerText = allColumns[ind].headerText;
                  if (allColumns[ind].filter)
                    column.filter = allColumns[ind].filter;
                }
              });
              for (let i = removeColumnIndexs.length - 1; i >= 0; i--) {
                data.splice(removeColumnIndexs[i], 1);
              }

              allColumns.forEach((column) => {
                let dataIndex = data.findIndex(
                  (d) => d.ColumnName == column.ColumnName
                );
                if (dataIndex < 0) {
                  isModify = true;
                  if (column.defaultVisible) column.IsVisible = true;
                  else column.IsVisible = false;
                  data.push(column);
                }
              });
              if (data.every((d) => d.IsVisible == false)) {
                data[0].IsVisible = true;
              }
              let position = 1;
              data.forEach((column) => {
                if (column.IsVisible) {
                  column.Position = position;
                  position = position + 1;
                }
              });
              if (isModify) {
                this.$api.columnProperties.save(
                  this.validViewName,
                  this.validTableName,
                  data
                );
              }

              if (this.noHide) {
                columns = allColumns;
              } else {
                columns = data;
              }
            }
            columns.forEach((column) => {
              let origin = this.$func.getColumnProperty(column.ColumnName);
              if (!column.class) column.class = origin.class;
              if (!column.filter) column.filter = origin.filter;
              if (!column.Width) column.Width = origin.Width;
              if (
                column.headerText &&
                column.headerText.length > 0 &&
                this.currentLang == "tw"
              ) {
                column.text = column.headerText;
              } else if (!column.text || column.text.length == 0) {
                column.text =
                  this.$t(`columnName.${column.ColumnName.toLowerCase()}`) ||
                  origin.text;
              }
            });

            this.validColumns = columns;
          });
      } else {
        allColumns.forEach((column) => {
          let origin = this.$func.getColumnProperty(column.ColumnName);
          if (!column.class) column.class = origin.class;
          if (!column.filter) column.filter = origin.filter;
          if (!column.Width) column.Width = origin.Width;
          if (column.headerText && column.headerText.length > 0) {
            column.text = column.headerText;
          } else if (!column.text || column.text.length == 0) {
            column.text = origin.text;
          }
        });
        this.validColumns = allColumns;
      }
    },

    currentIndexChanged() {
      this.$emit("currentIndexChanged", this.currentIndex);
    },
    click(item, index) {
      this.currentIndex = index;
      this.$emit("click", item);
    },
    dbClicked(item) {
      this.$emit("dbClicked", item);
    },
    mousedown(event, column) {
      this.currentTh = column;
      this.startOffset = column.Width - event.pageX;
      this.cursor = "col-resize";
    },
    showColumnProperty() {
      if (this.noHide || !this.isBusiness) {
        return;
      }
      if (this.validViewName && this.validTableName)
        this.trigger = !this.trigger;
    },
    save(columns) {
      this.$api.columnProperties
        .save(this.validViewName, this.validTableName, columns)
        .then(() => {
          this.updateValidColumns();
        });
    },
    saveColumn(name, width) {
      if ((this.validViewName, this.validTableName)) {
        this.$api.columnProperties.saveWidth(
          this.validViewName,
          this.validTableName,
          name,
          width
        );
      }
    },
  },
  mounted() {
    if (this.isBusiness) {
      const vm = this;
      document.addEventListener("mousemove", function (e) {
        if (vm.currentTh) {
          let width = vm.startOffset + e.pageX;
          if (width < 60) return;
          vm.currentTh.Width = width;
        }
      });

      document.addEventListener("mouseup", function () {
        if (vm.currentTh)
          vm.saveColumn(vm.currentTh.ColumnName, vm.currentTh.Width);
        vm.currentTh = undefined;
        vm.cursor = "pointer";
      });
    }
    if (this.validTableName) {
      this.updateValidColumns();
    }
  },
};
</script>
