<template>
  <div>
    <pageTable
      @getItems="getDevices"
      :hasAdd="false"
      :hasRemove="false"
      :hasPage="false"
      :hasLine="hasLine"
      @setLine="setLine"
      @editItem="editDevice"
      :items="devices"
      :columns="columns"
      idProperty="terminalId"
    >
      <template slot="button" slot-scope="props">
        <slot name="dataButtons" :index="props.index" :item="props.item">
          <button
            type="button"
            class="btn btn-outline-primary btn-icon"
            @click="goTableMaps(props.item)"
          >
            <i class="fas fa-chair"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary btn-icon"
            @click="openTakeOutQRcode(props.item)"
          >
            <i class="fas fa-shopping-bag"></i>
          </button>
        </slot>
      </template>
    </pageTable>

    <itemModal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="save"
      modalClass="modal-lg"
      title="商店資訊"
    >
      <div slot="item">
        <div class="row">
          <ul
            v-if="isMultiLanguage"
            class="col-12 nav nav-tabs my-2 px-1"
            id="myTab"
            role="tablist"
          >
            <li
              v-if="isMultiLanguage"
              class="nav-item"
              @click="currentSettingLang(0)"
            >
              <a
                class="nav-link lang-tab show active"
                data-toggle="tab"
                href="#chineseTab"
                role="tab"
                aria-controls="chineseTab"
                aria-selected="true"
                >{{ $t("dataModalPart.chinese") }}</a
              >
            </li>
            <li
              v-if="hasEnglish"
              class="nav-item"
              @click="currentSettingLang(1)"
            >
              <a
                class="nav-link lang-tab"
                data-toggle="tab"
                href="#englishTab"
                role="tab"
                aria-controls="englishTab"
                aria-selected="false"
                >{{ $t("dataModalPart.english") }}</a
              >
            </li>
            <li
              v-if="hasJapanese"
              class="nav-item"
              @click="currentSettingLang(2)"
            >
              <a
                class="nav-link lang-tab"
                data-toggle="tab"
                href="#japaneseTab"
                role="tab"
                aria-controls="japaneseTab"
                aria-selected="false"
                >{{ $t("dataModalPart.japanese") }}</a
              >
            </li>
            <li
              v-if="hasKorean"
              class="nav-item"
              @click="currentSettingLang(3)"
            >
              <a
                class="nav-link lang-tab"
                data-toggle="tab"
                href="#koreanTab"
                role="tab"
                aria-controls="koreanTab"
                aria-selected="false"
                >{{ $t("dataModalPart.korean") }}</a
              >
            </li>
            <li
              v-if="hasFrench"
              class="nav-item"
              @click="currentSettingLang(4)"
            >
              <a
                class="nav-link lang-tab"
                data-toggle="tab"
                href="#frenchTab"
                role="tab"
                aria-controls="frenchTab"
                aria-selected="false"
                >{{ $t("dataModalPart.french") }}</a
              >
            </li>
          </ul>
          <div class="col-12">
            <div class="flex mb-3">
              <div id="order-url" class="pl-0 col-9 col-sm-10 col-lg-11">
                <a :href="device.OrderURL" target="blank">
                  {{ device.OrderURL }}
                </a>
              </div>
              <button
                class="btn btn-primary col-3 col-sm-2 col-lg-1"
                type="button"
                @click="copyURL"
              >
                {{ $t("button.copy") }}
              </button>
            </div>
            <hr />
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="storeName">{{ $t("modal.name") }}</label>
              <input
                type="text"
                class="form-control"
                id="storeName"
                v-model="settingLang.OnlineOrderStoreName"
                required
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="inDoneTitle">{{ $t("modal.indonetitle") }}</label>
              <input
                type="text"
                class="form-control"
                id="inDoneTitle"
                v-model="settingLang.OnlineOrderInDoneTitle"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="outDoneTitle">{{ $t("modal.outdonetitle") }}</label>
              <input
                type="text"
                class="form-control"
                id="outDoneTitle"
                v-model="settingLang.OnlineOrderOutDoneTitle"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="phone">{{ $t("modal.phone") }}</label>
              <input
                type="text"
                class="form-control"
                id="phone"
                v-model="device.OnlineOrderPhone"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="waitingType">{{ $t("modal.waitingtype") }}</label>
              <select
                class="form-control"
                id="waitingType"
                v-model="waitingType"
              >
                <option
                  v-for="type in waitingTypes"
                  :value="type.value"
                  :key="type.value"
                >
                  {{ type.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="waitingNum">{{ $t("modal.waitingnum") }}</label>
              <input
                type="tel"
                class="form-control"
                id="waitingNum"
                v-model="device.OnlineOrderWaitingNum"
                @keyup="checkMinus"
                required
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="waitingInterval">{{
                $t("modal.waitinginterval")
              }}</label>
              <select
                class="form-control"
                id="waitingInterval"
                v-model="waitingInterval"
              >
                <option
                  v-for="type in waitingIntervalTypes"
                  :value="type.value"
                  :key="type.value"
                >
                  {{ type.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="waitingInterval == 0" class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="waitingIntervalCustomize">{{
                $t("modal.waitingintervalcustomize")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="waitingIntervalCustomize"
                v-model="device.OnlineOrderWaitingInterval"
                @keyup="checkMinus"
                required
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="lastOrderMinutes">{{
                $t("modal.lastorderminutes")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="lastOrderMinutes"
                v-model="device.OnlineOrderLastOrderMinutes"
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="reserveDays">{{ $t("modal.reservedays") }}</label>
              <select
                class="form-control"
                id="reserveDays"
                v-model="reserveDays"
              >
                <option
                  v-for="type in reserveDaysTypes"
                  :value="type.value"
                  :key="type.value"
                >
                  {{ type.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="onlineOrderInSalPriceType">{{
                $t("modal.onlineorderinsalpricetype")
              }}</label>
              <select
                class="form-control"
                id="onlineOrderInSalPriceType"
                v-model="device.OnlineOrderInSalPriceType"
              >
                <option
                  v-for="type in salPriceTypes"
                  :value="type.value"
                  :key="type.value"
                >
                  {{ type.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="onlineOrderPickSelfSalPriceType">{{
                $t("modal.onlineorderpickselfsalpricetype")
              }}</label>
              <select
                class="form-control"
                id="onlineOrderPickSelfSalPriceType"
                v-model="device.OnlineOrderPickSelfSalPriceType"
              >
                <option
                  v-for="type in salPriceTypes"
                  :value="type.value"
                  :key="type.value"
                >
                  {{ type.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="reserveDays == 1" class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="reserveDaysCustomize">{{
                $t("modal.reservedayscustomize")
              }}</label>
              <input
                type="text"
                class="form-control"
                id="reserveDaysCustomize"
                v-model="device.OnlineOrderReserveDays"
                @keyup="checkMinus"
                required
              />
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="onlineOrderIntervalCounts">每區間單數上限</label>
              <input
                type="text"
                class="form-control"
                id="onlineOrderIntervalCounts"
                v-model="device.OnlineOrderIntervalCounts"
                @keyup="checkMinus"
                required
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="address">{{ $t("modal.address") }}</label>
              <input
                type="text"
                class="form-control"
                id="address"
                v-model="settingLang.OnlineOrderAddress"
              />
            </div>
          </div>

          <div class="col-12 row mb-3">
            <div class="col-md-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isOnlineOrderOpen"
                  v-model="device.IsOnlineOrderOpen"
                />
                <label class="custom-control-label" for="isOnlineOrderOpen"
                  >營業中</label
                >
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isOnlineOrderShowIntroduction"
                  v-model="device.IsOnlineOrderShowIntroduction"
                />
                <label
                  class="custom-control-label"
                  for="isOnlineOrderShowIntroduction"
                  >顯示簡介</label
                >
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isOnlineOrderShowCheckAnnouncement"
                  v-model="device.IsOnlineOrderShowCheckAnnouncement"
                />
                <label
                  class="custom-control-label"
                  for="isOnlineOrderShowCheckAnnouncement"
                  >顯示結帳說明</label
                >
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isOnlineOrderShowUniformNumber"
                  v-model="device.IsOnlineOrderShowUniformNumber"
                />
                <label
                  class="custom-control-label"
                  for="isOnlineOrderShowUniformNumber"
                  >顯示統編</label
                >
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isOnlineOrderShowVehicleNumber"
                  v-model="device.IsOnlineOrderShowVehicleNumber"
                />
                <label
                  class="custom-control-label"
                  for="isOnlineOrderShowVehicleNumber"
                  >顯示載具</label
                >
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isOnlineOrderHasTitleMemo"
                  v-model="device.IsOnlineOrderHasTitleMemo"
                />
                <label
                  class="custom-control-label"
                  for="isOnlineOrderHasTitleMemo"
                  >顯示單據備註</label
                >
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isOnlineOrderHasDetailMemo"
                  v-model="device.IsOnlineOrderHasDetailMemo"
                />
                <label
                  class="custom-control-label"
                  for="isOnlineOrderHasDetailMemo"
                  >顯示明細備註</label
                >
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isOnlineOrderHasFoodUnit"
                  v-model="device.IsOnlineOrderHasFoodUnit"
                />
                <label
                  class="custom-control-label"
                  for="isOnlineOrderHasFoodUnit"
                  >顯示單位</label
                >
              </div>
            </div>
            <div v-if="isERP" class="col-md-4 col-lg-3">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="isOnlineOrderCheckStock"
                  v-model="device.IsOnlineOrderCheckStock"
                />
                <label
                  class="custom-control-label"
                  for="isOnlineOrderCheckStock"
                  >檢查庫存</label
                >
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="introduction">簡介</label>
              <textarea
                class="form-control"
                v-model="settingLang.OnlineOrderIntroduction"
                id="introduction"
                rows="4"
              ></textarea>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="checkAnnouncement">結帳說明</label>
              <textarea
                class="form-control"
                v-model="settingLang.OnlineOrderCheckAnnouncement"
                id="checkAnnouncement"
                rows="4"
              ></textarea>
            </div>
          </div>
          <div class="col-12" v-if="device.IsOnlineOrderHasTitleMemo">
            <div class="form-group">
              <label for="onlineOrderTradeMemoTitle">單據備註</label>
              <input
                type="text"
                class="form-control"
                id="onlineOrderTradeMemoTitle"
                v-model="settingLang.OnlineOrderTradeMemoTitle"
              />
            </div>
          </div>
          <div class="col-12" v-if="device.IsOnlineOrderHasDetailMemo">
            <div class="form-group">
              <label for="onlineOrderDetailMemoTitle">明細備註</label>
              <input
                type="text"
                class="form-control"
                id="onlineOrderDetailMemoTitle"
                v-model="settingLang.OnlineOrderDetailMemoTitle"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="image-group">
              <label for="onlineOrderImage"
                >店家圖片
                <input
                  class="form-control"
                  id="onlineOrderImage"
                  type="file"
                  @change="saveImg"
                  hidden
                />
                <div v-if="img" style="position: relative">
                  <img :src="img" alt="店家圖片" />
                  <a @click.prevent="removeImg" class="img-delete"
                    ><i class="fas fa-times"></i
                  ></a>
                </div>
                <div v-else class="form-control img-empty"></div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </itemModal>
    <itemModal
      modalId="takeOutQRcodeModal"
      :isShowModal="isShowTakeOutModal"
      :submitName="submitName"
      @onClosed="isShowTakeOutModal = !isShowTakeOutModal"
      @onSaved="downloadQRcode"
      :title="modalTitle"
      :modalClass="modalClass"
    >
      <div slot="item">
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <canvas
              ref="qrcodeCanvas"
              width="500"
              height="500"
              style="display: none"
            ></canvas>
            <img
              ref="qrcodeImg"
              src=""
              alt=""
              style="width: 480px; max-width: 100%"
            />
          </div>
        </div>
      </div>
    </itemModal>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";
import QRCode from "qrcode";

export default {
  data() {
    return {
      img: "",
      devices: [],
      device: {
        OnlineOrderInSalPriceType: 0,
        OnlineOrderPickSelfSalPriceType: 0,
      },
      isShowModal: false,
      isShowTakeOutModal: false,
      submitName: "download",
      waitingTypes: [],
      waitingType: 0,
      waitingIntervalTypes: [],
      waitingInterval: "",
      waitingIntervalCustomize: "",
      reserveDaysTypes: [],
      reserveDays: "",
      reserveDaysCustomize: "",
      salPriceTypes: [],
      currentLangValue: 0,
      hasEnglish: window.localStorage["hasEnglish"] == "true",
      hasJapanese: window.localStorage["hasJapanese"] == "true",
      hasKorean: window.localStorage["hasKorean"] == "true",
      hasFrench: window.localStorage["hasFrench"] == "true",
      settingLang: {
        LanguageType: null,
        DeviceId: "",
        OnlineOrderStoreName: "",
        OnlineOrderInDoneTitle: "",
        OnlineOrderOutDoneTitle: "",
        OnlineOrderAddress: "",
        OnlineOrderIntroduction: "",
        OnlineOrderCheckAnnouncement: "",
        OnlineOrderTradeMemoTitle: "",
        OnlineOrderDetailMemoTitle: "",
      },
      takeOutQRcodeUrl: "",
    };
  },
  components: {
    pageTable,
    itemModal,
  },
  computed: {
    isShow() {
      return window.localStorage["productType"] == 0;
    },
    modalClass() {
      return this.isShow ? "modal-lg" : "modal-md";
    },
    isCatering() {
      return window.localStorage["industryType"] == 1;
    },
    isMultiLanguage() {
      return (
        this.hasEnglish || this.hasJapanese || this.hasKorean || this.hasFrench
      );
    },
    columns() {
      return [
        { name: "DeviceName" },
        { name: "OnlineOrderStoreName" },
        { name: "OnlineOrderPhone" },
        { name: "OnlineOrderAddress" },
      ];
    },
    currentLang() {
      return this.$store.state.currentLang;
    },
    hasLine() {
      return window.localStorage["hasLineOrder"] == "true";
    },
    isERP() {
      return window.localStorage["isERP"] == "true";
    },
  },
  watch: {
    currentLang() {
      this.setOptions();
    },
    waitingType(value) {
      this.device.OnlineOrderWaitingType = value;
    },
    waitingInterval(value) {
      if (
        value == 5 ||
        value == 10 ||
        value == 15 ||
        value == 20 ||
        value == 30 ||
        value == 60
      ) {
        this.device.OnlineOrderWaitingInterval = value;
      }
    },
    reserveDays(value) {
      if (value < 1) {
        this.device.OnlineOrderReserveDays = value;
      }
    },
    isShowModal(value) {
      if (value) {
        let langTabs = document.querySelectorAll(".lang-tab");
        langTabs.forEach((tab) => {
          tab.classList.remove("show");
          tab.classList.remove("active");
        });
        langTabs[0].classList.add("show");
        langTabs[0].classList.add("active");
      }
    },
  },
  methods: {
    currentSettingLang(langValue) {
      if (this.currentLangValue == langValue) return;
      if (this.currentLangValue != 0) {
        let checkLangIndex = this.device.Languages.findIndex((lang) => {
          return lang.LanguageType == this.currentLangValue;
        });
        if (checkLangIndex > -1) {
          this.device.Languages[checkLangIndex] = this.settingLang;
        } else {
          this.device.Languages.push(this.settingLang);
        }
      }
      this.currentLangValue = langValue;
      this.setSettingLang(this.currentLangValue);
    },
    setSettingLang(langValue) {
      if (langValue == 0) {
        this.settingLang = {
          DeviceId: this.device.DeviceId,
          OnlineOrderStoreName: this.device.OnlineOrderStoreName,
          OnlineOrderInDoneTitle: this.device.OnlineOrderInDoneTitle,
          OnlineOrderOutDoneTitle: this.device.OnlineOrderOutDoneTitle,
          OnlineOrderAddress: this.device.OnlineOrderAddress,
          OnlineOrderIntroduction: this.device.OnlineOrderIntroduction,
          OnlineOrderCheckAnnouncement:
            this.device.OnlineOrderCheckAnnouncement,
          OnlineOrderTradeMemoTitle: this.device.OnlineOrderTradeMemoTitle,
          OnlineOrderDetailMemoTitle: this.device.OnlineOrderDetailMemoTitle,
        };
      } else {
        let langObj = this.device.Languages.find((lang) => {
          return lang.LanguageType == langValue;
        });
        if (langObj) {
          this.settingLang = {
            LanguageType: langObj.LanguageType,
            DeviceId: langObj.DeviceId,
            OnlineOrderStoreName: langObj.OnlineOrderStoreName,
            OnlineOrderInDoneTitle: langObj.OnlineOrderInDoneTitle,
            OnlineOrderOutDoneTitle: langObj.OnlineOrderOutDoneTitle,
            OnlineOrderAddress: langObj.OnlineOrderAddress,
            OnlineOrderIntroduction: langObj.OnlineOrderIntroduction,
            OnlineOrderCheckAnnouncement: langObj.OnlineOrderCheckAnnouncement,
            OnlineOrderTradeMemoTitle: langObj.OnlineOrderTradeMemoTitle,
            OnlineOrderDetailMemoTitle: langObj.OnlineOrderDetailMemoTitle,
          };
        } else {
          this.settingLang = {
            LanguageType: langValue,
            DeviceId: this.device.DeviceId,
            OnlineOrderStoreName: "",
            OnlineOrderInDoneTitle: "",
            OnlineOrderOutDoneTitle: "",
            OnlineOrderAddress: "",
            OnlineOrderIntroduction: "",
            OnlineOrderCheckAnnouncement: "",
            OnlineOrderTradeMemoTitle: "",
            OnlineOrderDetailMemoTitle: "",
          };
        }
      }
    },
    openTakeOutQRcode(item) {
      this.modalTitle = this.$t("modal.qrcode");
      this.isShowTakeOutModal = !this.isShowTakeOutModal;
      this.takeOutQRcodeUrl = `${process.env.VUE_APP_ONLINEORDER_URL}/${item.PartId}/takeout`;
      this.generateQRCodeWithLogo(this.takeOutQRcodeUrl);
    },
    async generateQRCodeWithLogo(url) {
      const canvas = this.$refs.qrcodeCanvas;
      const img = this.$refs.qrcodeImg;
      const ctx = canvas.getContext("2d");

      // const now = new Date();
      // const year = now.getFullYear();
      // const month = String(now.getMonth() + 1).padStart(2, "0");
      // const day = String(now.getDate()).padStart(2, "0");

      const text = url;
      const logoUrl = require("@/imgs/LogoCircle.png");

      // 生成 QR Code
      const qrCodeDataUrl = await QRCode.toDataURL(text, {
        errorCorrectionLevel: "M",
        width: 500,
      });

      const qrCodeImg = new Image();
      qrCodeImg.src = qrCodeDataUrl;

      qrCodeImg.onload = () => {
        const qrCodeSize = canvas.width * 1;
        const logoSize = qrCodeSize * 0.2;
        const qrCodeX = (canvas.width - qrCodeSize) / 2;
        const qrCodeY = (canvas.height - qrCodeSize) / 2;
        const logoX = (canvas.width - logoSize) / 2;
        const logoY = (canvas.height - logoSize) / 2;

        ctx.drawImage(qrCodeImg, qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

        const logoImg = new Image();
        logoImg.src = logoUrl;

        logoImg.onload = () => {
          ctx.drawImage(logoImg, logoX, logoY, logoSize, logoSize);
          const dataUrl = canvas.toDataURL("image/png");
          this.qrcodeDataUrl = dataUrl;
          img.src = dataUrl;
        };
      };
    },
    base64ToBinary(base64Data) {
      const binaryString = atob(base64Data);
      const byteArray = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      return byteArray;
    },
    downloadQRcode() {
      const base64Data = this.qrcodeDataUrl.split(",")[1];
      const binaryData = this.base64ToBinary(base64Data);
      const blob = new Blob([binaryData], { type: "image/png" });
      const fileName = "外帶QRcode";
      this.$fileSaver(blob, fileName);
    },
    goTableMaps(item) {
      if (item.TableOrderType != 1 && !this.isCatering) {
        this.$func.message.alert("此收銀台不支援桌位功能");
        return;
      }
      this.$router.push({
        name: "TableMaps",
        params: {
          id: item.DeviceId,
          tablemapid: item.PartId,
          type: item.TableOrderType,
        },
      });
    },
    checkMinus(event) {
      if (event.target.value != event.target.value.replace(/[^0-9]/g, "")) {
        event.target.value = event.target.value.replace(/[^0-9]/g, "");
      }
    },
    setOptions() {
      this.waitingTypes = this.$enum.waitingType.getAll();
      this.waitingIntervalTypes = this.$enum.waitingIntervalType.getAll();
      this.reserveDaysTypes = this.$enum.reserveDaysType.getAll();
      this.salPriceTypes = this.$enum.salPriceType.getAll();
    },
    setLine(item) {
      this.$router.push({
        name: "LineOrderSetting",
        params: { id: item.DeviceId },
      });
    },
    getDevices() {
      this.device = {};
      const vm = this;
      this.$api.devices
        .getByOnlineOrder()
        .then((data) => (vm.devices = data))
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    getImg(id) {
      this.img = "";
      const vm = this;
      this.$api.devices.getOnlineOrderImg(id).then((data) => {
        if (data.size > 0) {
          let reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            vm.img = reader.result;
          };
        }
      });
    },
    getDevice(id) {
      const vm = this;
      this.$api.devices.getOnlineOrder(id).then((data) => {
        vm.device = data;
        vm.currentLangValue = 0;
        vm.setSettingLang(vm.currentLangValue);
        vm.waitingType = data.OnlineOrderWaitingType;
        if (
          data.OnlineOrderWaitingInterval != 5 &&
          data.OnlineOrderWaitingInterval != 10 &&
          data.OnlineOrderWaitingInterval != 15 &&
          data.OnlineOrderWaitingInterval != 20 &&
          data.OnlineOrderWaitingInterval != 30 &&
          data.OnlineOrderWaitingInterval != 60
        ) {
          vm.waitingInterval = 0;
        } else {
          vm.waitingInterval = data.OnlineOrderWaitingInterval;
        }
        if (data.OnlineOrderReserveDays > 1) {
          vm.reserveDays = 1;
        } else {
          vm.reserveDays = data.OnlineOrderReserveDays;
        }
      });
    },
    editDevice(device) {
      this.getDevice(device.DeviceId);
      this.getImg(device.DeviceId);
      this.isShowModal = !this.isShowModal;
    },
    save() {
      const vm = this;
      if (this.currentLangValue != 0) {
        let checkLangIndex = this.device.Languages.findIndex((lang) => {
          return lang.LanguageType == this.currentLangValue;
        });
        if (checkLangIndex > -1) {
          this.device.Languages[checkLangIndex] = this.settingLang;
        } else {
          this.device.Languages.push(this.settingLang);
        }
      } else {
        this.device.OnlineOrderStoreName =
          this.settingLang.OnlineOrderStoreName;
        this.device.OnlineOrderInDoneTitle =
          this.settingLang.OnlineOrderInDoneTitle;
        this.device.OnlineOrderOutDoneTitle =
          this.settingLang.OnlineOrderOutDoneTitle;
        this.device.OnlineOrderAddress = this.settingLang.OnlineOrderAddress;
        this.device.OnlineOrderIntroduction =
          this.settingLang.OnlineOrderIntroduction;
        this.device.OnlineOrderCheckAnnouncement =
          this.settingLang.OnlineOrderCheckAnnouncement;
        this.device.OnlineOrderTradeMemoTitle =
          this.settingLang.OnlineOrderTradeMemoTitle;
        this.device.OnlineOrderDetailMemoTitle =
          this.settingLang.OnlineOrderDetailMemoTitle;
      }
      this.device.OnlineOrderWaitingNum = parseInt(
        this.device.OnlineOrderWaitingNum
      );
      this.device.OnlineOrderWaitingInterval = parseInt(
        this.device.OnlineOrderWaitingInterval
      );
      this.device.OnlineOrderReserveDays = parseInt(
        this.device.OnlineOrderReserveDays
      );
      this.device.OnlineOrderLastOrderMinutes = parseInt(
        this.device.OnlineOrderLastOrderMinutes
      );
      if (this.device.OnlineOrderWaitingInterval <= 0) {
        this.$func.message.alert("請設定大於0的候餐時間區間 !");
        return;
      }
      if (
        this.device.OnlineOrderWaitingType == 2 &&
        this.device.OnlineOrderReserveDays != -1 &&
        this.device.OnlineOrderWaitingNum > this.device.OnlineOrderReserveDays
      ) {
        this.$func.message.alert("不可設定大於可預訂天數的最快候餐天數 !");
        return;
      }
      this.$api.devices
        .updateOnlineOrder(this.device.DeviceId, this.device)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getDevices();
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    saveImg(e) {
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.img = reader.result;
      };
      let formData = new FormData();
      formData.append("file", file);
      this.$api.devices.saveOnlineOrderImg(this.device.DeviceId, formData);
    },
    removeImg() {
      const img = document.getElementById("onlineOrderImage");
      img.value = "";
      const vm = this;
      this.$func.message.confirm("確定刪除圖片嗎 ?").then((result) => {
        if (result.isConfirmed) {
          vm.$api.devices.removeOnlineOrderImg(vm.device.DeviceId).then(() => {
            vm.img = "";
          });
        }
      });
    },
    copyURL() {
      const range = document.createRange();
      const texts = document.querySelector("#order-url");
      range.selectNode(texts);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeAllRanges();
      this.$func.message.success("複製成功 !");
    },
  },
  mounted() {
    this.setOptions();
  },
};
</script>
