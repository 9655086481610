<template>
  <form
    :id="modalId"
    class="modal fade"
    role="dialog"
    data-backdrop="static"
    @submit.prevent="onSaved"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="modalClass"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="close" @click="onClosed">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row" slot="item">
            <div class="col-lg-4">
              <div class="card card-table">
                <div class="card-header">
                  <button
                    v-if="
                      !isVisionEditing &&
                      currentVisionIndex > -1 &&
                      customerVisions.length > 0
                    "
                    type="button"
                    class="btn btn-outline-danger btn-icon"
                    @click="removeVision"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
                <div class="card-body">
                  <tablePart
                    :columns="visionColumns"
                    :items="customerVisions"
                    idProperty="CustomerVisionId"
                    :parentIndex="currentVisionIndex"
                    @currentIndexChanged="currentVisionIndexChanged"
                    tableName="customerVision"
                  >
                    <template slot="th-before">
                      <slot name="th-before" />
                    </template>
                    <template slot="th-after">
                      <slot name="th-after" />
                    </template>
                    <template slot="td-before" slot-scope="props">
                      <slot name="td-before" :item="props.item" />
                    </template>
                    <template slot="td-after" slot-scope="props">
                      <slot name="td-after" :item="props.item" />
                    </template>
                  </tablePart>
                </div>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="card card-table">
                <div class="card-header">
                  <button
                    :style="
                      isVisionEditing ? 'display: none;' : 'display: block;'
                    "
                    type="button"
                    class="btn btn-outline-primary btn-icon"
                    @click="addVision"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                  <button
                    v-if="currentVisionIndex > -1"
                    :style="
                      isVisionEditing ? 'display: none;' : 'display: block;'
                    "
                    type="button"
                    class="btn btn-outline-success btn-icon"
                    @click="editVision"
                  >
                    <i class="fas fa-pen"></i>
                  </button>
                  <button
                    :style="
                      isVisionEditing ? 'display: block;' : 'display: none;'
                    "
                    type="button"
                    class="btn btn-outline-primary btn-icon"
                    @click="saveVisionEdit"
                  >
                    <i class="fas fa-check"></i>
                  </button>
                  <button
                    :style="
                      isVisionEditing ? 'display: block;' : 'display: none;'
                    "
                    type="button"
                    class="btn btn-outline-danger btn-icon"
                    @click="cancelVisionEdit"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </div>
                <div class="card-body" style="overflow-y: auto">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="builddate" class="col-4 col-form-label"
                          >{{ $t("modal.builddate")
                          }}<span style="color: red">*</span></label
                        >
                        <div class="col">
                          <input
                            id="builddate"
                            type="date"
                            class="form-control"
                            v-model="editingVision.BuildDate"
                            required
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="builder" class="col-4 col-form-label">{{
                          $t("modal.builder")
                        }}</label>
                        <div class="col">
                          <input
                            id="builder"
                            type="text"
                            class="form-control"
                            v-model="editingVision.Builder"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="visionType" class="col-4 col-form-label">{{
                          $t("modal.visiontype")
                        }}</label>
                        <div class="col">
                          <select
                            v-if="isVisionEditing"
                            id="visionType"
                            class="form-control"
                            v-model="editingVision.VisionType"
                          >
                            <option
                              v-for="type in visionTypes"
                              :key="type.value"
                              :value="type.value"
                            >
                              {{ $t(`option.visionType.${type.name}`) }}
                            </option>
                          </select>
                          <input
                            v-else
                            id="visionType"
                            type="text"
                            class="form-control"
                            v-model="editingVision.VisionTypeText"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label
                          for="prescription"
                          class="col-4 col-form-label"
                          >{{ $t("modal.prescription") }}</label
                        >
                        <div class="col">
                          <input
                            id="prescription"
                            type="text"
                            class="form-control"
                            v-model="editingVision.Prescription"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div
                        v-if="editingVision.VisionType == 3"
                        class="form-group d-flex col-12 p-0"
                        style="width: auto"
                      >
                        <label for="visionMemo" class="col-4 col-form-label"
                          >處方說明</label
                        >
                        <div class="col">
                          <input
                            id="visionMemo"
                            type="text"
                            class="form-control"
                            v-model="editingVision.VisionMemo"
                            :readonly="!isVisionEditing"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- <hr class="w-100" /> -->
                    <ul
                      class="col-12 nav nav-tabs mt-2 px-1"
                      id="myTab"
                      role="tablist"
                    >
                      <li class="nav-item" :style="isCL ? 'display: none' : ''">
                        <a
                          class="nav-link advanced-setting show active"
                          data-toggle="tab"
                          href="#old"
                          role="tab"
                          aria-controls="old"
                          aria-selected="false"
                          >舊度</a
                        >
                      </li>
                      <li class="nav-item" :style="isCL ? 'display: none' : ''">
                        <a
                          class="nav-link advanced-setting"
                          data-toggle="tab"
                          href="#ar"
                          role="tab"
                          aria-controls="ar"
                          aria-selected="false"
                          >AR</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          ref="exTab"
                          class="nav-link advanced-setting"
                          data-toggle="tab"
                          href="#ex"
                          role="tab"
                          aria-controls="ex"
                          aria-selected="false"
                          >驗光</a
                        >
                      </li>
                      <li class="nav-item" :style="isCL ? 'display: none' : ''">
                        <a
                          class="nav-link advanced-setting"
                          data-toggle="tab"
                          href="#ad"
                          role="tab"
                          aria-controls="ad"
                          aria-selected="false"
                          >進階</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link advanced-setting"
                          data-toggle="tab"
                          href="#final"
                          role="tab"
                          aria-controls="final"
                          aria-selected="false"
                          >調整/最終</a
                        >
                      </li>
                    </ul>
                    <div
                      class="col-12 tab-content mt-2"
                      style="min-width: 540px"
                      id="myTabContent"
                    >
                      <div
                        class="row tab-pane fade old-content show active"
                        :style="isCL ? 'display: none' : ''"
                        id="old"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div class="col-4 col-lg-3 col-xl-2 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                            style="height: 32px"
                          >
                            　
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="SPH"
                                class="col-12 col-form-label pr-0"
                                >SPH</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="CYL"
                                class="col-12 col-form-label pr-0"
                                >CYL</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="AX" class="col-12 col-form-label pr-0"
                                >AX</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="VA" class="col-12 col-form-label pr-0"
                                >VA</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="ADD"
                                class="col-12 col-form-label pr-0"
                                >ADD</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="VPB"
                                class="col-12 col-form-label pr-0"
                                >垂直P/B</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="HPB"
                                class="col-12 col-form-label pr-0"
                                >水平P/B</label
                              >
                            </div>
                          </div>
                          <div class="col-12 pr-0">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="PD" class="col col-form-label pr-0"
                                >PD</label
                              >
                              <input
                                id="PD"
                                type="text"
                                class="form-control"
                                v-model="editingVision.PupillaryDistance"
                                :readonly="!isVisionEditing"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="DH" class="col-12 col-form-label pr-0"
                                >DH</label
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-4 col-lg-4 col-xl-5 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            OD
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RSPH"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.RSPH"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RCYL"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.RCYL"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RAX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.RAX"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RVA"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Old_VA_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RADD"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.RADD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RVPB"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Old_V_PB_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RHPB"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Old_H_PB_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RPD"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.RPD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RDH"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.RDH"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 col-lg-4 col-xl-5 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            OS
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LSPH"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.LSPH"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LCYL"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.LCYL"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LAX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.LAX"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LVA"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Old_VA_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LADD"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.LADD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LVPB"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Old_V_PB_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LHPB"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Old_H_PB_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LPD"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.LPD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LDH"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.LDH"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-lg-11 col-xl-12">
                          <div
                            class="form-group d-flex col-12 p-0"
                            style="width: auto"
                          >
                            <label
                              for="visionMemo"
                              class="custom-memo-col col-form-label"
                              >{{ $t("modal.memo") }}</label
                            >
                            <div class="col px-0">
                              <input
                                id="visionMemo"
                                type="text"
                                class="form-control"
                                v-model="editingVision.Memo"
                                :readonly="!isVisionEditing"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 舊度 -->
                      <div
                        class="row tab-pane fade ar-content"
                        :style="isCL ? 'display: none' : ''"
                        id="ar"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div class="col-4 col-lg-3 col-xl-2 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                            style="height: 32px"
                          >
                            　
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="SPH"
                                class="col-12 col-form-label pr-0"
                                >SPH</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="CYL"
                                class="col-12 col-form-label pr-0"
                                >CYL</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="AX" class="col-12 col-form-label pr-0"
                                >AX</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="VA" class="col-12 col-form-label pr-0"
                                >VA</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="ADD"
                                class="col-12 col-form-label pr-0"
                                >ADD</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="VPB"
                                class="col-12 col-form-label pr-0"
                                >垂直P/B</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="HPB"
                                class="col-12 col-form-label pr-0"
                                >水平P/B</label
                              >
                            </div>
                          </div>
                          <div class="col-12 pr-0">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="PD" class="col col-form-label pr-0"
                                >PD</label
                              >
                              <input
                                id="PDAR"
                                type="text"
                                class="form-control"
                                v-model="editingVision.AR_PD"
                                :readonly="!isVisionEditing"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="DH" class="col-12 col-form-label pr-0"
                                >DH</label
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-4 col-lg-4 col-xl-5 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            OD
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RSPHAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_SPH_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RCYLAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_CYL_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RAXAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_AX_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RVAAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_VA_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RADDAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_ADD_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RVPBAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_V_PB_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RHPBAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_H_PB_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RPDAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_PD_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RDHAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_DH_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 col-lg-4 col-xl-5 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            OS
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LSPHAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_SPH_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LCYLAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_CYL_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LAXAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_AX_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LVAAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_VA_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LADDAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_ADD_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LVPBAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_V_PB_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LHPBAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_H_PB_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LPDAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_PD_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LDHAR"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AR_DH_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-lg-11 col-xl-12">
                          <div
                            class="form-group d-flex col-12 p-0"
                            style="width: auto"
                          >
                            <label
                              for="visionMemoAR"
                              class="custom-memo-col col-form-label"
                              >{{ $t("modal.memo") }}</label
                            >
                            <div class="col px-0">
                              <input
                                id="visionMemoAR"
                                type="text"
                                class="form-control"
                                v-model="editingVision.AR_Memo"
                                :readonly="!isVisionEditing"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- AR -->
                      <div
                        class="row tab-pane fade ex-content"
                        id="ex"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div class="col-4 col-lg-3 col-xl-2 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                            style="height: 32px"
                          >
                            　
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                v-if="!isCL"
                                for="SPH"
                                class="col-12 col-form-label pr-0"
                                >SPH</label
                              >
                              <label
                                v-else
                                for="H"
                                class="col-12 col-form-label pr-0"
                                >H</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                v-if="!isCL"
                                for="CYL"
                                class="col-12 col-form-label pr-0"
                                >CYL</label
                              ><label
                                v-else
                                for="V"
                                class="col-12 col-form-label pr-0"
                                >V</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                v-if="!isCL"
                                for="AX"
                                class="col-12 col-form-label pr-0"
                                >AX</label
                              ><label
                                v-else
                                for="HVID"
                                class="col-12 col-form-label pr-0"
                                >HVID</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                v-if="!isCL"
                                for="VA"
                                class="col-12 col-form-label pr-0"
                                >VA</label
                              >
                              <label
                                v-else
                                for="hitomi"
                                class="col-12 col-form-label pr-0"
                                >瞳孔大小</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="ADD"
                                class="col-12 col-form-label pr-0"
                                >ADD</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                v-if="!isCL"
                                for="VPB"
                                class="col-12 col-form-label pr-0"
                                >垂直P/B</label
                              >
                              <label
                                v-else
                                for="move"
                                class="col-12 col-form-label pr-0"
                                >移動量</label
                              >
                            </div>
                          </div>
                          <div v-if="!isCL" class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="HPB"
                                class="col-12 col-form-label pr-0"
                                >水平P/B</label
                              >
                            </div>
                          </div>
                          <div v-if="!isCL" class="col-12 pr-0">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="PD" class="col col-form-label pr-0"
                                >PD</label
                              >
                              <input
                                id="PDEX"
                                type="text"
                                class="form-control"
                                v-model="editingVision.EX_PD"
                                :readonly="!isVisionEditing"
                              />
                            </div>
                          </div>
                          <div v-if="!isCL" class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="DH" class="col-12 col-form-label pr-0"
                                >DH</label
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-4 col-lg-4 col-xl-5 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            OD
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RSPHEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_SPH_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RCYLEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_CYL_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RAXEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_AX_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RVAEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_VA_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RADDEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_ADD_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RVPBEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_V_PB_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-if="!isCL" class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RHPBEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_H_PB_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-if="!isCL" class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RPDEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_PD_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-if="!isCL" class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RDHEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_DH_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 col-lg-4 col-xl-5 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            OS
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LSPHEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_SPH_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LCYLEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_CYL_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LAXEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_AX_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LVAEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_VA_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LADDEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_ADD_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LVPBEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_V_PB_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-if="!isCL" class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LHPBEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_H_PB_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-if="!isCL" class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LPDEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_PD_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div v-if="!isCL" class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LDHEX"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.EX_DH_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-lg-11 col-xl-12">
                          <div
                            class="form-group d-flex col-12 p-0"
                            style="width: auto"
                          >
                            <label
                              for="visionMemoEX"
                              class="custom-memo-col col-form-label"
                              >{{ $t("modal.memo") }}</label
                            >
                            <div class="col px-0">
                              <input
                                id="visionMemoEX"
                                type="text"
                                class="form-control"
                                v-model="editingVision.EX_Memo"
                                :readonly="!isVisionEditing"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- EX -->
                      <div
                        class="row tab-pane fade ad-content"
                        :style="isCL ? 'display: none' : ''"
                        id="ad"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div class="col-3 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                            style="height: 32px"
                          >
                            　
                          </div>
                          <div class="col-12 pr-0">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="AD_P"
                                class="col-12 col-form-label pr-0"
                                >斜位稜鏡量</label
                              >
                            </div>
                          </div>
                          <div class="col-12 pr-0">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="AD_BI"
                                class="col-12 col-form-label pr-0"
                                >水平聚散力BI</label
                              >
                            </div>
                          </div>
                          <div class="col-12 pr-0">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="AD_BO"
                                class="col-12 col-form-label pr-0"
                                >水平聚散力BO</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="AD_NRA"
                                class="col-12 col-form-label pr-0"
                                >NRA</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="AD_PRA"
                                class="col-12 col-form-label pr-0"
                                >PRA</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="AD_FCC"
                                class="col-12 col-form-label pr-0"
                                >FCC</label
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-3 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            遠方
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_P_Far"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_P_Far"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_BI_Far"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_BI_Far"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_BO_Far"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_BO_Far"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_NRA_Far"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_NRA_Far"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_PRA_Far"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_PRA_Far"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_FCC_Far"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_FCC_Far"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-3 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            近方
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_P_Near"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_P_Near"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_BI_Near"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_BI_Near"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_BO_Near"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_BO_Near"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_NRA_Near"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_NRA_Near"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_PRA_Near"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_PRA_Near"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_FCC_Near"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_FCC_Near"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-3 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            近方+1.00
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_P_Near_1"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_P_Near_1"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_BI_Near_1"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_BI_Near_1"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_BO_Near_1"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_BO_Near_1"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_NRA_Near_1"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_NRA_Near_1"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_PRA_Near_1"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_PRA_Near_1"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="AD_FCC_Near_1"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.AD_FCC_Near_1"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div
                            class="form-group d-flex col-12 p-0"
                            style="width: auto"
                          >
                            <label
                              for="visionMemoAD"
                              class="custom-memo-col col-form-label"
                              >{{ $t("modal.memo") }}</label
                            >
                            <div class="col px-0">
                              <input
                                id="visionMemoAD"
                                type="text"
                                class="form-control"
                                v-model="editingVision.AD_Memo"
                                :readonly="!isVisionEditing"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- AD -->
                      <div
                        ref="finalTab"
                        class="row tab-pane fade final-content"
                        id="final"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div class="col-4 col-lg-3 col-xl-2 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                            style="height: 32px"
                          >
                            　
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="SPH"
                                class="col-12 col-form-label pr-0"
                                >SPH</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="CYL"
                                class="col-12 col-form-label pr-0"
                                >CYL</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="AX" class="col-12 col-form-label pr-0"
                                >AX</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="VA" class="col-12 col-form-label pr-0"
                                >VA</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="ADD"
                                class="col-12 col-form-label pr-0"
                                >ADD</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                v-if="!isCL"
                                for="VPB"
                                class="col-12 col-form-label pr-0"
                                >垂直P/B</label
                              >
                              <label
                                v-else
                                for="BC"
                                class="col-12 col-form-label pr-0"
                                >BC</label
                              >
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label
                                for="HPB"
                                v-if="!isCL"
                                class="col-12 col-form-label pr-0"
                                >水平P/B</label
                              >
                              <label
                                v-else
                                for="DIA"
                                class="col-12 col-form-label pr-0"
                                >DIA</label
                              >
                            </div>
                          </div>
                          <div class="col-12 pr-0">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="PD" class="col col-form-label pr-0"
                                >PD</label
                              >
                              <input
                                id="PDFinal"
                                type="text"
                                class="form-control"
                                v-model="editingVision.Final_PD"
                                :readonly="!isVisionEditing"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <label for="DH" class="col-12 col-form-label pr-0"
                                >DH</label
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-4 col-lg-4 col-xl-5 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            OD
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RSPHFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_SPH_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RCYLFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_CYL_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RAXFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_AX_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RVAFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_VA_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RADDFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_ADD_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RVPBFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_V_PB_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RHPBFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_H_PB_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RPDFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_PD_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="RDHFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_DH_OD"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 col-lg-4 col-xl-5 p-0">
                          <div
                            class="col-12 d-flex justify-content-center py-1"
                          >
                            OS
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LSPHFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_SPH_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LCYLFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_CYL_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LAXFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_AX_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LVAFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_VA_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LADDFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_ADD_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LVPBFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_V_PB_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LHPBFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_H_PB_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LPDFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_PD_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div
                              class="form-group d-flex col-12 p-0"
                              style="width: auto"
                            >
                              <div class="col px-0">
                                <input
                                  id="LDHFinal"
                                  type="text"
                                  class="form-control"
                                  v-model="editingVision.Final_DH_OS"
                                  :readonly="!isVisionEditing"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-lg-11 col-xl-12">
                          <div
                            class="form-group d-flex col-12 p-0"
                            style="width: auto"
                          >
                            <label
                              for="visionMemoFinal"
                              class="custom-memo-col col-form-label"
                              >{{ $t("modal.memo") }}</label
                            >
                            <div class="col px-0">
                              <input
                                id="visionMemoFinal"
                                type="text"
                                class="form-control"
                                v-model="editingVision.Final_Memo"
                                :readonly="!isVisionEditing"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Final -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";
import tablePart from "@/components/parts/tablePart";

export default {
  data() {
    return {
      visionTypes: this.$enum.visionType.getAll(),
      customerVisions: [],
      visionColumns: [{ name: "VisionTypeText" }, { name: "BuildDate" }],
      currentVisionIndex: -1,
      isVisionEditing: false,
      vision: {},
      editingVision: {},
      buildDate: "",
    };
  },
  props: {
    visionTrigger: false,
    customer: Object,
    title: String,
    modalId: {
      type: String,
      default: "itemModal",
    },
    modalClass: String,
  },
  components: {
    tablePart,
  },
  computed: {
    isCL() {
      return this.editingVision.VisionType == 2;
    },
  },
  watch: {
    isCL(value) {
      if (value) {
        if (
          !this.$refs.exTab.classList.contains("active") &&
          !this.$refs.finalTab.classList.contains("active")
        ) {
          this.$refs.exTab.click();
        }
      }
    },
    visionTrigger(value) {
      if (value) {
        $(`#${this.modalId}`).modal("toggle");
        window.onbeforeunload = () => {
          return true;
        };
        this.getCustomerVisions();
      } else {
        this.currentVisionIndex = -1;
        $(`#${this.modalId}`).modal("toggle");
        window.onbeforeunload = null;
      }
    },
    currentVisionIndex(value) {
      this.isVisionEditing = false;
      if (value > -1) {
        this.getVision();
      }
    },
  },
  methods: {
    getVision() {
      const vm = this;
      if (this.customerVisions.length > 0) {
        this.$api.customers
          .getVision(
            this.customerVisions[this.currentVisionIndex].CustomerVisionId
          )
          .then((data) => {
            vm.vision = data;
            vm.vision.VisionTypeText = vm.$enum.visionType.getByValue(
              vm.vision.VisionType
            ).text;
            vm.editingVision = vm.vision;
          })
          .catch((error) => vm.$func.message.error(error.data.Message));
      }
    },
    addVision() {
      this.isVisionEditing = true;
      this.editingVision = {};
    },
    editVision() {
      this.isVisionEditing = true;
    },
    saveVisionEdit() {
      if (!this.editingVision.BuildDate) {
        this.$func.message.alert("請選擇建立日期 !");
        return;
      }
      const vm = this;
      if (!this.editingVision.CustomerId) {
        this.editingVision.CustomerId = this.customer.CustomerId;
      }

      this.$api.customers
        .saveVision(this.editingVision)
        .then(() => {
          vm.$func.message.success("儲存成功 !");
          vm.isVisionEditing = false;
          vm.getCustomerVisions();
          vm.getVision();
        })
        .catch((error) => this.$func.message.error(error.data.Message));
    },
    cancelVisionEdit() {
      this.isVisionEditing = false;
      this.editingVision = this.vision;
    },
    removeVision() {
      const vm = this;
      this.$func.message.confirm("確定刪除此紀錄嗎 ?").then((result) => {
        if (result.isConfirmed) {
          vm.$api.customers
            .removeVision(vm.vision.CustomerVisionId)
            .then(() => {
              vm.$func.message.success("刪除成功 !");
              vm.getCustomerVisions();
              vm.currentVisionIndex = -1;
            })
            .catch((error) => this.$func.message.error(error.data.Message));
        }
      });
    },
    currentVisionIndexChanged(index) {
      this.currentVisionIndex = index;
    },
    onClosed() {
      this.$emit("onClosed");
    },
    getCustomerVisions() {
      this.isVisionEditing = false;
      const vm = this;
      if (this.customer.CustomerId) {
        vm.$api.customers.getVisions(vm.customer.CustomerId).then((data) => {
          vm.customerVisions = [];
          if (data.length > 0) {
            vm.customerVisions = data;
          } else {
            vm.currentVisionIndex = -1;
            vm.vision = {};
            vm.editingVision = {};
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.custom-memo-col {
  padding-left: 15px;
  @media screen and(min-width: 992px) {
    flex: 0 0 17%;
  }
}
.old-content.active,
.ar-content.active,
.ex-content.active,
.ad-content.active,
.final-content.active {
  display: flex !important;
}
</style>
