<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasStore="true"
      :hasEmployee="true"
      :hasTerminal="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
    />
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      columns: [
        { name: "TerminalNumber" },
        { name: "TerminalName" },
        { name: "EmployeeNumber" },
        { name: "EmployeeName" },
        { name: "CollectionPaymentType" },
        { name: "DirectoryName" },
        { name: "BusinessDay" },
        { name: "BusinessClass" },
        { name: "Price" },
        { name: "CreateTime" },
        { name: "Memo" },
      ],
    };
  },
  components: {
    conditionPart,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getCollectionPayment(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getCollectionPayment(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printCollectionPayment(condition);
    },
    getItems(condition) {
      const vm = this;
      this.$api.reports.getCollectionPayment(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
  },
};
</script>
