import { render, staticRenderFns } from "./PurchaseOrderData.vue?vue&type=template&id=388d47b2&"
import script from "./PurchaseOrderData.vue?vue&type=script&lang=js&"
export * from "./PurchaseOrderData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports