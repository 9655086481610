<template>
  <form @submit.prevent="onSave">
    <div class="row">
      <h3 class="col">{{ $t("modal.adddownload") }}</h3>
      <div class="col-auto">
        <button class="btn btn-primary upload" type="submit">
          <i class="fas fa-cloud-download-alt"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="card my-3">
          <div class="card-header">{{ $t("modal.basic") }}</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="downloadName">{{ $t("modal.name") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="downloadName"
                    v-model="download.DownloadName"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="memo">{{ $t("modal.memo") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="memo"
                    v-model="download.Memo"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="isFullDownload"
                    v-model="download.IsFullDownload"
                  />
                  <label class="custom-control-label" for="isFullDownload">{{
                    $t("modal.isfulldownload")
                  }}</label>
                </div>
              </div>
            </div>
            <div class="card my-3">
              <div class="card-header">
                <div class="custom-control custom-checkbox mr-sm-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="allTerminalCheck"
                    v-model="allTerminalCheck"
                  />
                  <label class="custom-control-label" for="allTerminalCheck">{{
                    $t("modal.terminal")
                  }}</label>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive custom-table">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>{{ $t("columnName.select") }}</th>
                        <th>{{ $t("columnName.storenumber") }}</th>
                        <th>{{ $t("columnName.storename") }}</th>
                        <th>{{ $t("columnName.terminalnumber") }}</th>
                        <th>{{ $t("columnName.terminalname") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="terminal in terminals"
                        :key="terminal.TerminalId"
                        @click="selectTerminal(terminal.TerminalId)"
                      >
                        <td>
                          <input
                            class="checkbox"
                            type="checkbox"
                            :value="terminal.TerminalId"
                            v-model="terminalIds"
                          />
                        </td>
                        <td>{{ terminal.StoreNumber }}</td>
                        <td>{{ terminal.StoreName }}</td>
                        <td>{{ terminal.TerminalNumber }}</td>
                        <td>{{ terminal.TerminalName }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card my-3">
          <div class="card-header">
            <div class="custom-control custom-checkbox mr-sm-2">
              <input
                type="checkbox"
                class="custom-control-input"
                id="allDetCheck"
                v-model="allDetCheck"
              />
              <label class="custom-control-label" for="allDetCheck">{{
                $t("modal.downloaditem")
              }}</label>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive custom-table">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>{{ $t("columnName.select") }}</th>
                    <th>{{ $t("columnName.item") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="det in downloadDets"
                    :key="det.DownloadItemType"
                    @click="selectItem(det.DownloadItemType)"
                  >
                    <td>
                      <input
                        class="checkbox"
                        type="checkbox"
                        :value="det.DownloadItemType"
                        v-model="downloadItemTypes"
                      />
                    </td>
                    <td>{{ det.DownloadItemName }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      download: {},
      downloadDets: [],
      terminals: [],
      terminalIds: [],
      downloadItemTypes: [],
      allTerminalCheck: false,
      allDetCheck: false,
    };
  },
  computed: {
    isDining() {
      return window.localStorage["isDining"] == "true";
    },
    isIndustryType() {
      return window.localStorage["isIndustryType"] == 1;
    },
  },
  watch: {
    allDetCheck(value) {
      this.downloadItemTypes = [];
      if (value) {
        this.downloadDets.forEach((item) => {
          this.downloadItemTypes.push(item.DownloadItemType);
        });
      }
    },
    allTerminalCheck(value) {
      this.terminalIds = [];
      if (value) {
        this.terminals.forEach((item) => {
          this.terminalIds.push(item.TerminalId);
        });
      }
    },
  },
  methods: {
    selectTerminal(id) {
      const index = this.terminalIds.indexOf(id);
      if (index >= 0) {
        this.terminalIds.splice(index, 1);
      } else {
        this.terminalIds.push(id);
      }
    },
    selectItem(type) {
      const index = this.downloadItemTypes.indexOf(type);
      if (index >= 0) {
        this.downloadItemTypes.splice(index, 1);
      } else {
        this.downloadItemTypes.push(type);
      }
    },
    setInitialDets() {
      const vm = this;
      this.$api.downloadDets.getEmpty().then((data) => {
        vm.downloadDets = data;
      });
    },
    onSave() {
      if (this.terminalIds.length == 0) {
        this.$func.message.alert("請選擇收銀台 !");
        return;
      }
      if (this.isDining || this.isIndustryType == 1) {
        this.download.TableMap = 16;
      }
      if (this.downloadItemTypes.length == 0) {
        this.$func.message.alert("請選擇項目 !");
        return;
      }
      this.download.EmployeeId = window.localStorage["userId"];
      const vm = this;
      this.$api.downloads
        .save({
          Download: vm.download,
          TerminalIds: vm.terminalIds,
          DownloadItemTypes: vm.downloadItemTypes,
        })
        .then(() => this.$router.go(-1));
    },
  },
  mounted() {
    const vm = this;
    this.$api.terminals.getNames().then((data) => {
      vm.terminals = data;
    });
    this.$api.downloadDets.getEmpty().then((data) => {
      vm.downloadDets = data;
      if (window.localStorage["isERP"] == "true") {
        vm.downloadDets.push({
          DownloadItemName: "倉庫",
          DownloadItemType: 15,
        });
      }
      if (
        window.localStorage["productType"] == 2 ||
        window.localStorage["productType"] == 4
      ) {
        vm.downloadDets.push({
          DownloadItemName: "功能鍵",
          DownloadItemType: 6,
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.upload {
  font-size: 24px;
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 60px;
}
</style>
