<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasBuyer="true"
      :hasFood="isDetail"
      :hasFoodKind="isDetail"
      :hasSupplier="true"
      :hasWarehouse="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :isPurchaseOrder="true"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :reportTypes="reportTypes"
      @trdbClicked="trdbClicked"
    >
      <template
        slot="dataButtons"
        slot-scope="props"
        v-if="reportType == 'simple'"
      >
        <button
          class="btn btn-outline-primary btn-icon"
          type="button"
          @click="trdbClicked(props.item)"
        >
          <i class="fas fa-file-invoice-dollar"></i>
        </button>
      </template>
    </condition-part>

    <purchaseData
      :trade="trade"
      :isShow="isShow"
      @onClosed="dataOnClosed"
    ></purchaseData>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";
import purchaseData from "./PurchaseData";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportTypes: ["simple", "detail"],
      reportType: "simple",
      trade: {},
      isShow: false,
    };
  },
  computed: {
    columns() {
      if (this.reportType == "detail") {
        return [
          { name: "PurchaseDate", text: "採購日期" },
          { name: "PurchaseNumber", text: "進貨單號" },
          { name: "PurchaseOrderNumber", defaultVisible: true },
          { name: "BuyerNumber" },
          { name: "BuyerName" },
          { name: "SupplierNumber" },
          { name: "SupplierName" },
          { name: "WarehouseNumber" },
          { name: "WarehouseName" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "FoodMemo" },
          { name: "Qty" },
          { name: "Cost" },
          { name: "Total" },
          { name: "PurchaseMemo" },
        ];
      } else if (this.reportType == "simple") {
        return [
          { name: "PurchaseDate", text: "採購日期" },
          { name: "PurchaseNumber", text: "進貨單號" },
          { name: "PurchaseOrderNumber", defaultVisible: true },
          { name: "BuyerNumber" },
          { name: "BuyerName" },
          { name: "SupplierNumber" },
          { name: "SupplierName" },
          { name: "Total" },
          { name: "YTaxPrice" },
          { name: "TaxPrice" },
          { name: "Memo" },
        ];
      } else {
        return undefined;
      }
    },
    isDetail() {
      return this.reportType == "detail";
    },
  },
  components: {
    conditionPart,
    purchaseData,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getPurchaseOrder(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getPurchaseOrder(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printPurchaseOrder(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;

      const vm = this;
      this.$api.reports.getPurchaseOrder(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    trdbClicked(purchase) {
      const vm = this;
      if (purchase) {
        this.$api.purchases
          .get(purchase.PurchaseId)
          .then((data) => {
            vm.trade = data;
            vm.isShow = !vm.isShow;
          })
          .catch(() => vm.$func.message.alert("查無此資料 !"));
      } else {
        this.$func.message.alert("請選擇一筆採購單 !");
      }
    },
    dataOnClosed() {
      this.isShow = !this.isShow;
    },
  },
};
</script>
