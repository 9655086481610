import {
  httpGet,
  httpPost,
  httpPut,
  httpPatch,
  httpDelete,
} from "@/request/http";

let path = "/devices";

export default {
  get(id) {
    return httpGet(`${path}/${id}`);
  },
  gets(businessId, dTO) {
    return httpGet(`/businesses/${businessId}/devices`, dTO);
  },
  save(item) {
    return httpPost(path, item);
  },
  put(item) {
    return httpPut(`${path}/${item.DeviceId}`, item);
  },
  remove(id) {
    return httpDelete(`${path}/${id}`);
  },
  getReceivablePages(dTO) {
    return httpGet(`${path}/receivables/pages`, dTO);
  },
  getElecInvoice(id) {
    return httpGet(`${path}/${id}/elecinvoice`);
  },
  saveElecInvoice(item) {
    return httpPost(`${path}/elecinvoice`, item);
  },
  deleteElecInvoice(id) {
    return httpDelete(`${path}/${id}/elecinvoice`);
  },
  getElecInvoiceVerify(item) {
    return httpPost(`${path}/elecinvoice/verify`, item);
  },
  getOnlineOrder(id) {
    return httpGet(`${path}/${id}/onlineorder`);
  },
  updateOnlineOrder(id, dTO) {
    return httpPatch(`${path}/${id}/onlineorder`, dTO);
  },
  getByUser() {
    return httpGet(`${path}/byuser`);
  },
  getByOnlineOrder() {
    return httpGet(`${path}/by_online_order`);
  },
  getOnlineOrderImg(id) {
    return httpGet(`${path}/${id}/onlineorder/image`, null, {
      responseType: "blob",
    });
  },
  saveOnlineOrderImg(id, formData) {
    return httpPut(`${path}/${id}/onlineorder/image`, formData);
  },
  removeOnlineOrderImg(id) {
    return httpDelete(`${path}/${id}/onlineorder/image`);
  },
  changeDevice(id, item) {
    return httpPost(`${path}/${id}/change-device`, item);
  },
  getApiKeys(id) {
    return httpGet(`${path}/${id}/credentials`);
  },
};
