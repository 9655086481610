<template>
  <div>
    <condition-part
      :noSearch="reportType == 'summary'"
      :hasTradeStatusType="true"
      :hasReportSourceType="true"
      :hasDate="true"
      :hasEatType="true"
      :hasStore="true"
      :hasTerminal="true"
      :hasEmployee="true"
      :hasCostType="true"
      :hasFood="isDetail"
      :hasFoodKind="isDetail"
      :hasExcel="reportType != 'summary'"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :reportTypes="reportTypes"
      @trdbClicked="trdbClicked"
      :hasTotal="hasTotal"
    >
      <template
        slot="dataButtons"
        slot-scope="props"
        v-if="reportType == 'simple'"
      >
        <button
          class="btn btn-outline-primary btn-icon"
          type="button"
          @click="trdbClicked(props.item)"
        >
          <i class="fas fa-file-invoice-dollar"></i>
        </button>
      </template>
    </condition-part>

    <salData :trade="trade" :isShow="isShow" @onClosed="dataOnClosed"></salData>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";
import salData from "./SalData";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportTypes: ["daystatistic", "simple", "detail", "summary"],
      reportType: "daystatistic",
      trade: {},
      isShow: false,
    };
  },
  computed: {
    hasUberEats() {
      return window.localStorage["hasUberEats"] == "true";
    },
    hasTotal() {
      return this.reportType == "daystatistic";
    },
    productType() {
      return parseInt(window.localStorage["productType"]);
    },
    columns() {
      if (this.reportType == "simple") {
        let s = [
          { name: "TerminalNumber" },
          { name: "TerminalName" },
          { name: "SalNumber" },
          { name: "BusinessDay" },
          { name: "BusinessClass" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "SalTime" },
          { name: "TradeType" },
          { name: "InvoiceNumber" },
          { name: "UniformNumber" },
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "Discount" },
          { name: "TotalDiscountPrice" },
          { name: "TotalFreePrice" },
          { name: "Total" },
          { name: "Cash" },
          { name: "CreditCardPrice" },
          { name: "CreditCardNumber" },
          { name: "EatType" },
          { name: "Memo" },
          { name: "StartTime" },
          { name: "EndTime" },
          { name: "PayType1" },
          { name: "PayType2" },
          { name: "LinePayPrice" },
        ];
        if (this.productType == 0) {
          s.push({ name: "Scan2PayPrice" });
        }
        if (window.localStorage["isERP"] == "true") {
          s.push({ name: "Cost" });
          s.push({ name: "Profit" });
        }
        if (window.localStorage["hasPGTalk"] == "true") {
          s.push({ name: "PGTalkPrice" });
          s.push({ name: "PGTalkPT" });
        }
        if (this.hasUberEats) {
          s.push({ name: "UberEatsPrice" });
        }
        if (localStorage.getItem("industryType") == 1) {
          s.push({ name: "TableMapName" });
        }
        return s;
      } else if (this.reportType == "detail") {
        let s = [
          { name: "TerminalNumber" },
          { name: "TerminalName" },
          { name: "SalNumber" },
          { name: "BusinessDay" },
          { name: "BusinessClass" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "SalerNumber" },
          { name: "SalerName" },
          { name: "SalTime" },
          { name: "TradeType" },
          { name: "InvoiceNumber" },
          { name: "UniformNumber" },
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "Degree" },
          { name: "TotalDiscountPrice" },
          { name: "TotalFreePrice" },
          { name: "SalTotal" },
          { name: "FoodKindName" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Qty" },
          { name: "SalPrice" },
          { name: "Total" },
          { name: "SalMemo" },
          { name: "FoodMemo" },
          { name: "OriginalSalNumber" },
          { name: "Cost" },
          { name: "TotalCost" },
          { name: "GrossProfit" },
          { name: "GrossMargin" },
          { name: "Cash" },
          { name: "CreditCardPrice" },
          { name: "PayType1" },
          { name: "PayType2" },
          { name: "EasyCardPrice" },
          { name: "LinePayPrice" },
        ];
        if (window.localStorage["hasBatchNumber"] == "true") {
          s.push({ name: "BatchNumber" });
        }
        if (window.localStorage["isERP"] == "true") {
          s.push({ name: "WarehouseNumber" });
          s.push({ name: "WarehouseName" });
        }
        if (localStorage.getItem("industryType") == 1) {
          s.push({ name: "TableMapName" });
        }
        return s;
      } else if (this.reportType == "daystatistic") {
        let s = [
          { name: "TerminalNumber" },
          { name: "TerminalName" },
          { name: "BusinessDay" },
          { name: "Total" },
          { name: "Cash" },
          { name: "CreditCardPrice" },
          { name: "DiscountPrice" },
          { name: "FreePrice" },
          { name: "PayType1" },
          { name: "PayType2" },
          { name: "LinePay" },
          { name: "TotalCost" },
          { name: "GrossProfit" },
          { name: "GrossMargin" },
          { name: "People", headerText: "來客數" },
          { name: "Sheets", headerText: "筆數" },
          { name: "FoodCounts" },
          { name: "FoodAverageSalPrice" },
          { name: "TotalPerReceipt" },
          { name: "FoodCountsPerReceipt" },
          { name: "PeoplePerReceipt" },
          { name: "TotalPerPerson" },
          { name: "FoodCountsPerPerson" },
        ];
        if (this.productType == 0) {
          s.push({ name: "Scan2Pay" });
        }
        if (window.localStorage["hasPGTalk"] == "true") {
          s.push({ name: "PGTalk" });
          s.push({ name: "PGTalkPT" });
        }
        if (this.hasUberEats) {
          s.push({ name: "UberEatsPrice" });
        }
        return s;
      } else {
        return undefined;
      }
    },
    isDetail() {
      return this.reportType == "detail";
    },
  },
  components: {
    conditionPart,
    salData,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getSale(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getSale(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printSale(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;
      this.trade = {};

      this.$api.reports.getSale(condition).then((data) => {
        this.items = data.Datas;
        this.pagination = data.Pagination;
      });
    },
    trdbClicked(sal) {
      if (this.reportType == "simple") {
        if (sal) {
          this.$api.sals
            .get(sal.SalId)
            .then((data) => {
              this.trade = data;
              this.isShow = !this.isShow;
            })
            .catch(() => this.$func.message.alert("查無此資料 !"));
        } else {
          this.$func.message.alert("請選擇一筆銷貨單 !");
        }
      }
    },
    dataOnClosed() {
      this.isShow = !this.isShow;
    },
  },
};
</script>
