export function getAll() {
  return [
    {
      value: 0,
      name: "None",
      text: "無",
    },
    {
      value: 1,
      name: "QRcode",
      text: "掃QR碼點餐",
    },
  ];
}
